<template>
    <v-container class="eval-wrapper">
        <v-row>
            <v-col cols="12" lg="1" class="pt-6"></v-col>
            <v-col cols="12" sm="7">
                <h1><Str index="evaluation.title"/></h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
                <Str index="evaluation.blurb"/>
            </v-col>
            <v-col class="pa-0">
                <QuestionSet @answers="answers" :debug="debug" :disabled="isComplete" :questionSet="QuestionSetController.questionSets[3]" @setController="setController" @validate="validate">
                    <template v-if="controller" v-slot:submit>
                        <v-container fluid dense>
                            <v-row class="pa-0" :class="[{'custom-pink':!isComplete} , {'custom-gray':isComplete}]">
                                <v-col class="pa-0" v-if="!isComplete">
                                    <v-btn @click="$router.push({name:'Dashboard'})" class="back-button" text dark><Str index="evaluation.button.back"/></v-btn>
                                </v-col>
                                <v-col class="pa-0" style="text-align: right;">
                                    <v-btn v-if="!isComplete" @click="controller.submit" class="send-button" :disabled="isComplete || !valid || QuestionSetController.busy" text dark><Str index="evaluation.button.send"/></v-btn>                        
                                    <v-btn v-else @click="$router.push({name:'Dashboard'})" class="send-button custom-midgray" text dark><Str index="evaluation.button.continue"/></v-btn>                        
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </QuestionSet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UserInfo from '@/components/Dashboard/UserInfo'
import QuestionSet from '@/components/QuestionSet/QuestionSet.vue'
export default {
    components: {
        UserInfo,
        QuestionSet
    },
    data(){
        return {
            form: {},
            controller: undefined
        }
    },
    methods: {
        answers(data){
            this.form = data
        },
        validate(valid){
            this.valid = valid
        },
        setController(controller){
            this.controller = controller
        }
    },
    computed: {
        QuestionSetController(){
            return this.$store.getters.QuestionSetController
        },
        language(){
            return this.$store.getters.LanguageController.language
        },
        isComplete(){
            return this.QuestionSetController.submissions[this.$route.meta.questionSet_id] ? true : false
        },
        debug(){
            return this.$store.getters.debug
        }
    }
}
</script>

<style lang="scss" scoped>
.eval-wrapper {
    max-width: 1650px;
}
h1{
    font-size: 4em;
    font-weight: 900;
    color: $pink;
}
@media only screen and (max-width: 370px) {
h1{
    font-size: 3em;
}
}
.custom-gray{
    background-color: $gray;
}
.custom-pink{
    background-color: $pink;
}
.back-button{
  background-color: $pink;
  border-radius: 0px;
  padding: 35px 50px 35px 50px !important;
}
.send-button{
  background-color: $gray;
  border-radius: 0px;
  padding: 35px 50px 35px 50px !important;
}
.custom-midgray{
    background-color: $midgray;
}
</style>