<template>
  <v-container>
    <v-row style="height:60px;"></v-row>      
    <v-row>
      <v-col class="col-left">
        <div style="padding: 0px 15px; margin: 25px;position: relative; top: -10px;">
          <v-row style="height:30px;"></v-row>
          <h1 class="text-h2 font-weight-bold"><Str index="invite_colleague.title"/></h1>
          <v-row style="height:30px;"></v-row>
          <h2><Str index="invite_colleague.fin_support.subtitle"/></h2>
          <div><Str index="invite_colleague.fin_support.content"/></div>        
          <h2><Str index="invite_colleague.bias.subtitle"/></h2>
          <p><Str index="invite_colleague.bias.content"/></p>  
        </div>
      </v-col>
      <v-col class="col-right" :style="{'background-image':`URL('${Background}')`}">
        <v-img style="position: absolute; left: -25px; top: 50%; transform: translateY(-50%);" :src="Decoration" width="400"/>
      </v-col>
    </v-row>
    <v-row class="footer"></v-row>
  </v-container>
</template>

<script>
import Decoration from '@/assets/shapes-decoration.svg'
import shape_2_yellow from '@/assets/shape_2_yellow.svg'
export default {
  computed: {
    Decoration(){
      return Decoration
    },
    Background(){
      return shape_2_yellow
    }
  }
}
</script>

<style lang="scss" scoped>
.col-left{
  background-color: white;
  color: $gray;
}
.col-right{
  position: relative;
  z-index: 10;
  // background-color: $yellow;
  background-size: cover;
}

.footer{
  z-index: 5;
  height: 60px;
  background-color: $midgray;
  position: relative;
  top: -20px;
}
</style>