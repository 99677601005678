<template>
<v-card tile flat>
  <v-container fluid>
    <v-row>
      <v-col>
        <h3><Str index="dashboard.certificates.title"/></h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="certificates.length==0" class="pa-0" style="margin-bottom: 135px;">
        <v-container fluid dense>
          <v-row class="custom-midgray">
            <v-col align-self="center" cols="1" style="text-align: right;">
              <!-- <v-icon large color="green">mdi-information</v-icon> -->
              <img width="35" :src="icons.info"/>
            </v-col>
            <v-col align-self="center">
              <Str index="dashboard.user certificates.none generated"/>
            </v-col>
          </v-row>
        </v-container>

      </v-col>
      <v-col v-else>
        <v-list dense>
          <v-list-item class="pa-0" v-for="(certificate, index) in certificates" :key="`certificate-${index}`" style="border-bottom: 3px solid lightgray;">
            <v-list-item-action style="margin: 0px 10px;">
              <router-link :to="{name:'Certificate',params:{record:index+1}}" target="_blank">
                <v-icon>
                  mdi-eye
                </v-icon>              
              </router-link>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-subtitle>
                  <Str index="dashboard.certificate.generic_name"/> {{ index+1 }} <v-icon small>mdi-rhombus-medium</v-icon><span style="margin-left:-3px;">{{new Date(certificate.date).toISOString().substr(0,10)}}</span>
              </v-list-item-subtitle>
            </v-list-item-content>             
            <v-list-item-action style="margin: 0px;">
              <div>
                <v-btn class="debug" v-if="debug" icon @click="controller.deleteCertificate(certificate)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>

                <v-btn icon @click="download(index)">
                  <v-icon>mdi-download-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <vue-html2pdf
            v-if="certificate"
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="`certificate-${index+1}_${new Date(certificate.date).toISOString().substr(0,10)}`"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="letter"
            pdf-orientation="landscape"
            pdf-content-width="800px"

            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
            >
              <section slot="pdf-content">
                <CertificateTemplate :certificate="certificate"/>
              </section>
        </vue-html2pdf>
      </v-col>
    </v-row>
            
    <v-row :style="{'border': controller.queuedCredits>0 ? '3px solid #bcafb6' : 'none'}">
        <v-col v-if="$vuetify.breakpoint.width>399" class="custom-pink" :cols="controller.queuedCredits>0 ? 5 : 12"></v-col>
        <v-col class="custom-gray px-0" v-if="controller.queuedCredits>0">
        <v-btn text dark @click="controller.generateCertificate()">
            <Str index="dashboard.certificate.cta.generate"/><v-icon>mdi-menu-right</v-icon>
        </v-btn>
    </v-col>
    </v-row>
  </v-container>  
</v-card>
</template>

<script>
import icon_info from '@/assets/icons/ic-msg-info-success.svg'
import icon_eye from '@/assets/icons/ic-view-eye.svg'
import VueHtml2pdf from 'vue-html2pdf'
import CertificateTemplate from '@/components/Certificate/template.vue'
export default {
  props: {
    debug: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
      CertificateTemplate,
      VueHtml2pdf
  },
  data(){
    return {
      certificate: null,
      index: null,
      timer: null
    }
  },
  methods: {
    download(index){
      let self = this
      self.index = index
      clearTimeout(self.timer)
      let certificates = self.certificates
      self.certificate = certificates[index]
      self.timer = setTimeout(() => {
        self.$refs.html2Pdf.generatePdf()
        self.certificate = null
        self.index = null
      }, 800);
    }
  },
  computed: {
    controller(){
      return this.$store.getters.CreditController
    },
    certificates(){
      return this.controller.certificates
    },
    icons(){
      return {
        info: icon_info,
        eye: icon_eye
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-pink{
  background-color: $pink;
}
.custom-midgray{
  background-color: $midgray;
}
.custom-gray{
  background-color: $gray;
  text-align: center;
}
</style>