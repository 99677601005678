<template>
  <v-card flat tile>
    <v-card-title class="custom-gray">
        <span v-html="section.text[language]"/>
    </v-card-title>
    <v-card-subtitle v-if="section.subtext && section.subtext[language] && section.subtext[language].length>0">
        <div style="margin-top: 25px;">
            <span v-html="section.subtext[language]"/>
        </div>
    </v-card-subtitle>
    <v-card-text>
        <!-- <json-viewer :value="section.questions"/> -->
        <Question v-model="model[section.id]" v-for="question in section.questions" @resetInput="resetInput" :key="`question-${question.id}`" :rules="rules" :language="language" :questionSet="questionSet" :section="section" :question="question" />
    </v-card-text>
  </v-card>
</template>

<script>
import Question from './Question'
export default {
    created(){
        let section = this.section
        let model = this.model
        // model[section.id] = {}
        this.$set(model,section.id,{})
    },
    props: {
        rules: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        questionSet: {
            type: Object,
            required: true
        },
        section: {
            type: Object,
            required: true
        }
    },
    components:{
        Question
    },
    data(){
        return {
            form: {}
        }
    },
    methods: {
        resetInput(uid){
            this.$emit('resetInput',uid)
        }
    },
    computed: {
        model(){
            return this.$attrs.value
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-gray{
    background-color: $gray;
    color: white;
}
</style>