<template>
    <v-container class="videoList">
        <v-row no-gutters>
            <v-col class="pa-0">
                <div class="video-card-container">
                    <VideoCard class="video-card" v-for="(video, videoIndex) in videos" :videoIndex="videoIndex" :debug="debug" :controller="controller" :video="video" :language="language" :key="`video-card-${video.id}`"/>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import VideoCard from './lib/videoCard'
export default {
    props: {
        controller: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        debug: {
            type: Boolean,
            required: true
        }
    },
    components: {
        VideoCard
    },
    computed: {
        videos(){
            return this.controller.videos
        }
    }
}
</script>

<style lang="scss" scoped>
.video-card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px 40px;
}

.video-card{
    width: 31%;
}

@media only screen and (max-width: 1200px) {
    .video-card{
        width: 45%;
    }
}

@media only screen and (max-width: 800px) {
    .video-card{
        width: 100%;
    }
}
</style>