<template>
    <v-container fluid dense>
        <v-row>
            <v-col class="font-weight-bold">
                <span v-if="question.required" :class="{['custom-color']:question.required}">*</span>                
                <span v-html="question.text[language]"/>
            </v-col>
            <v-col align-self="start">
                <v-radio-group v-model="model.answers.value" :row="question.row" :required="question.required" :rules="question.required ? rules.answered : []">
                    <v-radio v-for="choice in question.choices" :value="choice.value" :key="`${questionSet.id}-${section.id}-${question.id}-${choice.id}`">
                        <template v-slot:label>
                            <span v-html="choice.text[language] "/>
                        </template>
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    created(){
        this.init()
        this.question.$q = {
            init: this.init,
            reset: this.reset,
            randomize: this.randomize
        }
    },
    name: "ratingTable",
    props: {
        rules: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        section:{
            type: Object,
            required: true
        },
        questionSet:{
            type: Object,
            required: true
        },
        question:{
            type: Object,
            required: true
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        init(){
            let model = this.model
            let value = null
            let other = null
            let answers = {
                value,
                other
            }
            this.$set(model,'answers',answers)            
        },
        reset(){
            this.init()
            this.$emit('resetInput',this._uid)
        },
        randomize(){
            let question = this.question
            
            function getRandomInt(max) {
                return Math.floor(Math.random() * max);
            }

            let index = getRandomInt(question.choices.length)
            this.model.answers.value = question.choices[index].value


        }
    },
    computed: {
        model(){
            return this.$attrs.value
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-color{
    color: $pink;
}
</style>