<template>
<div>
  <v-container fluid style="width:100%;" v-if="$store.getters.accreditation_expired!==true">  
    <v-row style="z-index: 5;" :class="$vuetify.breakpoint.width>768 ? 'flex-row':'flex-column'">
      <v-col cols="2" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
      <v-col class="col-left">
        <div style="padding: 0px 15px; margin: 25px;position: relative; top: -10px;">
          <h1><Str index="invite_colleague.title"/></h1>
          <h2><Str index="invite_colleague.fin_support.subtitle"/></h2>
          <div><Str index="invite_colleague.fin_support.content"/></div>        
          <h2><Str index="invite_colleague.bias.subtitle"/></h2>
          <p><Str index="invite_colleague.bias.content"/></p>  
        </div>
      </v-col>
      <v-col class="col-right" :style="{'background-image':`URL('${Background}')`}">
        <v-form v-model="valid" ref="form" lazy-validation id="invite-colleague-form">
          <h1 style="color: white;"><Str index="invite_colleague.form.title"/></h1>
          <h2><Str index="invite_colleague.form.subtitle"/></h2>
          <p><Str index="invite_colleague.form.content"/></p>
          <h3><Str index="invite_colleague.form.cta.subtitle"/></h3>
          <v-text-field :label="$store.getters.LabelController.getLabel('invite_colleague.form.label.email_address')" v-model="form.email" required :rules="rules.email"/>
          <h3><Str index="invite_colleague.form.label.your_name"/></h3>
          <v-text-field v-model="form.name" disabled/>
          <v-checkbox v-model="form.email_consent" :label="$store.getters.LabelController.getLabel('invite_colleague.form.label.consent')" required :rules="rules.consent"/>
        </v-form>
        <!-- <v-img style="position: absolute; left: -25px; top: 50%; transform: translateY(-50%);" :src="Decoration" width="400"/> -->

        <!-- <v-btn @click="complete" class="test-button" text dark>Complete</v-btn> -->
        <v-btn @click="submit" class="send-button" :disabled="!valid" text dark><Str index="invite_colleague.form.label.send"/></v-btn>
      </v-col>
      <v-col cols="2" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
    </v-row>
    <v-row class="footer pa-0">
      <v-col style="text-align: right; position: relative;" class="pa-0">
      </v-col>
    </v-row>
  </v-container>
  
  <v-dialog v-model="ui.thankYou" width="800" persistent>
    <v-card>
      <v-container fluid dense>
        <template v-if="response.status=='skipped'">
          <v-row no-gutters>
            <v-col cols="1" align-self="center">
              <img width="30" :src="Alert"/>
            </v-col>
            <v-col align-self="center">
              <h1 style="line-height: 1em;"><Str index="invite_colleague.response.headline"/></h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"/>
            <v-col align-self="start">
                <Str index="invite_colleague.response.invitee_already_enrolled"/>
            </v-col>
          </v-row>        
        </template>

        <template v-else>
          <v-row>
            <v-col cols="1" align-self="center">
              <img width="30" :src="Success"/>
            </v-col>
            <v-col align-self="center">
              <h1 style="line-height: 1em;"><Str index="invite_colleague.response.headline"/></h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"/>
            <v-col align-self="center">
                <Str index="invite_colleague.response.appreciate_support_note"/>
            </v-col>
          </v-row>
        </template>



      </v-container>
      <v-card-actions class="custom-gray pa-0">
        <v-spacer></v-spacer>
        <v-btn @click="ui.thankYou=false" color="green" x-large dark tile><Str index="invite_colleague.form.label.close"/></v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</div>
</template>

<script>
import Alert from '@/assets/icons/ic-msg-alert.svg'
import Success from '@/assets/icons/ic-msg-info-success.svg'
import Decoration from '@/assets/shapes-decoration.svg'
import shape_2_yellow from '@/assets/shape_2_yellow.svg'
export default {
  mounted(){
    this.reset()
  },
  data(){
    return {
      ui: {
        thankYou: false
      },
      valid: false,
      formErrors: [],
      form: {
        email: null,
        name: null,
        email_consent: false
      },
      rules: {
        email: [this.notNull, this.validEmail],
        consent: [this.isChecked]
      },
      response: {
        status: 200
      }
    }
  },
  methods: {
    isChecked(v){
      if(!v){
        return this.$store.getters.LabelController.getLabel("authentication.form.label.must_consent")
      }
      return true
    },
    notNull(v){
      if(!v || v.length==0){
          return this.$store.getters.LabelController.getLabel("authentication.form.label.required_field")
      }
      return true
    },
    validEmail(email){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!(email && re.test(email))){
          return this.$store.getters.LabelController.getLabel("invite_colleague.form.label.invalid_email")
        }
        return true
    },
    validate() {
      return new Promise((resolve)=>{
        let self = this
        self.$refs.form.validate()
        setTimeout(() => {
          resolve(self.valid)
        }, 200);
      })
    },
    reset() {
      this.valid = false
      this.$refs.form.reset()
      let self = this
      setTimeout(() => {
        self.form.name = `${self.$store.getters.UserController.user.attributes.given_name} ${self.$store.getters.UserController.user.attributes.family_name}`
      }, 200);
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    async submit(){      
      if(await this.validate()){
        // this.response.status = this.response.status==200 ? 400 : 200
        let packet = JSON.stringify(this.form)
        packet = JSON.parse(packet)
        packet.language = this.language
        packet.hubspot_id = this.$store.getters.UserController.user.attributes['hubspot_id']
        let response = await this.sendRequest('post','/api/invite',packet)
        this.response.status = response.data.sendResult
        this.ui.thankYou = true
        this.reset()
      }
    }
  },
  computed: {
    Decoration(){
      return Decoration
    },
    Background(){
      return shape_2_yellow
    },
    Alert(){
      return Alert
    },
    Success(){
      return Success
    },
    language(){
      return this.$store.getters.LanguageController.language
    }
  }
}
</script>

<style lang="scss" scoped>
#invite-colleague-form {
    padding: 0px 15px 0px 120px;
}

@media only screen and (max-width: 600px) {
#invite-colleague-form {    
    padding-left: 0;
}
}
h1{
  font-size: 3em;
  font-weight: bold;
}
.col-left{
  padding-top: 60px;
  background-color: white;
  color: $gray;
}
.col-right{
  padding-top: 60px;
  position: relative;
  z-index: 8;
  background-size: cover;
}
@media only screen and (max-width: 600px) {
.col-right{ 
    background-image: unset;
    background-color: $yellow;
}
}

.footer{
  z-index: 5;
  height: 60px;
  background-color: $midgray;
  position: relative;
  top: -20px;
}

.send-button, .test-button{
  z-index: 10; 
  position: absolute; 
  bottom: -40px; 
  right: 0px;
  background-color: $pink;
  color: #ffffff;
  border-radius: 0px;
  padding: 35px 50px 35px 50px !important;
}
.test-button{
  right: 200px;
  padding: 10px !important;
  background-color: $blue;
}

.custom-gray{
  background-color: $gray;
}

.v-btn:not(.v-btn--round).v-size--default {
    color: #ffffff !important;
}

::v-deep .v-messages__message, ::v-deep .v-input--has-state.error--text .v-label {
    color: deeppink !important;
}
</style>