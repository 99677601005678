<template>
    <v-container fluid dense>
        <v-row v-if="question.text[language]">
            <v-col>
                <span v-if="question.required" class="custom-color">*</span>
                <span style="font-weight: bold;" v-html="question.text[language]"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col align-self="start">
                <v-textarea v-model="model.answers.value" outlined :label="question.label[language] ? question.label[language] : ''" :required="question.required" :rules="question.required ? rules.answered : []"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    created(){
        this.init()
        this.question.$q = {
            init: this.init,
            reset: this.reset,
            randomize: this.randomize
        }
    },
    name: "ratingTable",
    props: {
        rules: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        section:{
            type: Object,
            required: true
        },
        questionSet:{
            type: Object,
            required: true
        },
        question:{
            type: Object,
            required: true
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        init(){
            let model = this.model
            let value = null
            let other = null
            let answers = {
                value,
                other
            }
            this.$set(model,'answers',answers)
        },
        reset(){
            this.init()
            this.$emit('resetInput',this._uid)
        },
        randomize(){
            this.model.answers.value = 'Magna amet ad deserunt esse mollit exercitation labore quis. Ipsum nostrud eu nostrud est officia. Pariatur sunt eiusmod dolore exercitation eiusmod occaecat ut reprehenderit. Officia sint do proident do nulla irure deserunt do fugiat.'
        }
    },
    computed: {
        model(){
            return this.$attrs.value
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-color{
    color: $pink;
}
</style>