<template>
  <v-container class="pledge-wrapper">
    <v-row class="flex-column-reverse flex-md-row">
        <v-col sm="12" md="7">
            <h1 :style="{'font-size':$vuetify.breakpoint.width<768 ? '2em' : '5em'}"><Str index="pledge.title"/></h1>
        </v-col>
        <v-col :style="{'padding-top' : $vuetify.breakpoint.width<960 ? '60px' : '12px'}">
          <UserInfo/>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" v-if="$route.name!='Print Pledge' && $route.name!='Print Follow-Up'">
        <div v-if="!isComplete && pledge.id==1">
            <h2><Str index="pledge.instructions.title"/></h2>
            <h4 class="mb-6"><Str index="pledge.instructions.subtitle"/></h4>
            <p class="my-4"><Str index="pledge.instructions.headline"/></p>
            <p class="my-4"><Str index="pledge.instructions.step1"/></p>
            <p class="my-4"><Str index="pledge.instructions.step2"/></p>
            <p class="my-4"><Str index="pledge.instructions.step3"/></p>
            <p class="my-4"><Str index="pledge.instructions.step4"/></p>
        </div>
        <div v-if="isComplete && pledge.id==1">
            <p class="my-4"><Str index="pledge.initial_completed.body"/></p>
        </div>
        <div v-if="!isComplete && pledge.id==2">
            <h2><Str index="pledge.followup.title"/></h2>
            <p class="my-4"><Str index="pledge.followup.body"/></p>
        </div>
        <div v-if="isComplete && pledge.id==2">
            <p class="my-4"><Str index="pledge.followup_completed.body"/></p>
        </div>
      </v-col>
      <v-col class="pa-0">
        <!-- <Pledge :pledge="pledge" :PledgeController="PledgeController" :debug="debug" @completion="completion"/> -->
        <div v-if="isComplete" style="margin-left: 24px; margin-right: 24px;">
          <v-row class="custom-midgray mt-0">
              <v-col class="py-6 align-center d-flex">
                  <v-btn fab color="green" small>
                  <v-icon color="black" large>mdi-information-variant</v-icon>
                  </v-btn>
                  <template v-if="pledge.id==1">
                      <Str class="pl-1 font-weight-bold" index="pledge.initial_completed.title"/>
                  </template>
                  <template v-if="pledge.id==2">
                      <Str class="pl-1 font-weight-bold" index="pledge.followup_completed.title"/>
                  </template>
              </v-col>
          </v-row>
          <v-row class="custom-green">
              <v-col>
                  <v-container pa-0>
                      <v-row>
                          <v-col cols="7" style="text-align: left; align-self: center">
                              <span v-if="isComplete && questionSet_id==1"><Str index="pledge.submitted_on"/>: {{ new Date(QuestionSetController.submissions[questionSet_id].created).toLocaleDateString($store.getters.LanguageController.language, {weekday: "short",year: "numeric",month: "short",day: "numeric"}) }}</span>
                              <span v-if="isComplete && questionSet_id==2"><Str index="pledge.followup_submitted_on"/>: {{ new Date(QuestionSetController.submissions[questionSet_id].created).toLocaleDateString($store.getters.LanguageController.language, {weekday: "short",year: "numeric",month: "short",day: "numeric"}) }}</span>                      
                          </v-col>
                          <v-col cols="5" style="justify-content:flex-end; align-items:center; display:flex;">
                              <v-btn v-if="$route.name!='Print Pledge' && $route.name!='Print Follow-Up'" text @click="print" :class="{'pa-0':$vuetify.breakpoint.width<360}" :outlined="$vuetify.breakpoint.width<360">
                                  <Str class="font-weight-bold" index="pledge.print"/>
                                  <v-icon style="margin-left: 10px;" color="white">mdi-printer</v-icon>
                              </v-btn>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-col>
          </v-row>
      </div>        
        <QuestionSet v-if="QuestionSetController.initialized" :debug="debug" :disabled="isComplete" :questionSet="QuestionSetController.questionSets[questionSet_id]" @setController="setController" @validate="validate">
          <template v-if="controller" v-slot:submit>
            <v-container fluid dense>
                <v-row class="pa-0" :class="[{'custom-pink':!isComplete} , {'custom-gray':isComplete}]">
                    <v-col class="pa-0" v-if="!isComplete" id="back-button-wrapper">
                        <v-btn @click="$router.push({name:'Dashboard'})" class="back-button" text dark>&lt;&lt; <Str index="pledge.go_back"/></v-btn>
                    </v-col>
                    <v-col class="pa-0" style="text-align: right;">
                        <v-btn v-if="!isComplete" @click="submitPledge" class="send-button" :disabled="isComplete || !valid || QuestionSetController.busy" text dark><Str index="pledge.do_submit"/></v-btn>
                        <v-btn v-else @click="$router.push({name:'Dashboard'})" class="send-button custom-midgray-4" text dark>
                            <Str v-if="$route.name!='Print Pledge' && $route.name!='Print Follow-Up'" index="pledge.do_continue"/>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>            
          </template>
        </QuestionSet>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QuestionSet from '@/components/QuestionSet/QuestionSet.vue'
import Pledge from '@/components/Pledges/TakePledge.vue'
import UserInfo from '@/components/Dashboard/UserInfo.vue'
export default {
  name: 'PledgeView',
  components: {
    UserInfo,
    Pledge,
    QuestionSet
  },
  data(){
    return {
      ui: {
        complete: false
      },
      valid: false,
      controller: undefined,
      timer: null
    }
  },
  methods: {
    print(){
        this.$router.push({path:`${this.$route.path}/print`})
        clearTimeout(this.timer)
        let self = this

        setTimeout(() => {
            window.print()
            setTimeout(() => {
                self.$router.push({path:`${self.$route.matched[0].path}`})
            }, 800);
        }, 800);
    },
    completion(packet){
      this.ui.complete = packet.complete
    },
    validate(valid){
      this.valid = valid
    },
    setController(controller){
      this.controller = controller
    },
    async submitPledge(){
      let self = this
      let pledge = this.$route.name
      let controller = this.controller

      if(pledge == 'Initial Pledge'){
        let answers = controller.getAnswers()
  
        let input = {
          1: answers[1][1].answers.value,
          2: answers[2][1].answers.value,
          3: answers[3][1].answers.value,
          4: answers[3][2].answers.value,
          5: answers[4][1].answers.value,
          6: answers[4][2].answers.value
        }
        let packet = {}
        for (let index = 1; index <= 6; index++) {        
          packet[`pledge_question_${index}`] = input[index]
        }

        
        clearTimeout(self.timer)
        let delay = self.UserController.hs.data.busy ? 3000 : 0
        self.timer = setTimeout(async () => {
          let hsResponse = await self.UserController.hs.product.update(packet)
          console.log('submitPledge', {packet, pledge, hsResponse})           
        }, delay);


      }
      await controller.submit()
    }
  },
  computed:{
    debug(){
      return this.$store.getters.debug
    },
    QuestionSetController(){
      return this.$store.getters.QuestionSetController
    },
    pledges(){
      let initialPledgeIndex = this.$router.options.routes.findIndex((item)=>{return item.name=='Initial Pledge'})
      let followUpIndex = this.$router.options.routes.findIndex((item)=>{return item.name=='Follow Up'})
      let pledges = this.QuestionSetController.initialized ? [
          this.QuestionSetController.questionSets[this.$router.options.routes[initialPledgeIndex].meta.questionSet_id],
          this.QuestionSetController.questionSets[this.$router.options.routes[followUpIndex].meta.questionSet_id]
      ] : []
      return pledges
    },
    questionSet_id(){
      return this.$route.meta.questionSet_id
    },
    pledge(){
      return this.pledges.filter((pledge)=>{return pledge.id==this.questionSet_id})[0]
    },
    isComplete(){
      return this.QuestionSetController.initialized && this.QuestionSetController.submissions[this.questionSet_id] ? true : false
    },
    UserController(){
        return this.$store.getters.UserController
    }
  }
}
</script>

<style lang="scss" scoped>
.pledge-wrapper {
    max-width: 1650px;
}
h1{
    font-size: 5em;
    font-weight: 900;
    color: $pink;
    text-align: center;
}
.custom-gray{
    background-color: $gray;
}
.custom-green{
    background-color: $green;
    color: white;
}
.step{
    font-size: 1.5em;
    line-height: 1.2em;
    padding: 15px;
    color: white;
    font-weight: bold;
    background-color: $gray;
}

.footer{
    background-color: $pink;
}

.footer .col:nth-child(2){
    background-color: $gray;
}

.custom-pink{
    background-color: $pink;
}
.back-button{
  background-color: $pink;
  border-radius: 0px;
  padding: 35px 50px 35px 50px !important;
}
.send-button{
  background-color: $gray;
  border-radius: 0px;
  padding: 35px 50px 35px 50px !important;
}
.custom-midgray{
    background-color: $midgray;
}
.custom-midgray-4 {
    background-color: $midgray-4;
}

@media only screen and (max-width: 370px) {
    #back-button-wrapper {
        text-align: center;
        
        .back-button {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}
</style>
