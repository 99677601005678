<template>
  <v-container style="max-width:1650px;">
    <v-row no-gutters style="height:60px;"></v-row>
    <template v-if="$store.getters.accreditation_expired!==true">
        <v-row> 
                <h1><Str index="header.faq"/></h1>
        </v-row>
        <v-row>      
            <v-text-field v-model="searchString" :label="labels.search_bar._text[language]" clearable @keyup="queueSearch" @click:clear="clear" @click:append="search({searchString})">
                <template v-slot:append>
                    <div class="mag">
                        <v-icon color="white">mdi-magnify</v-icon>
                    </div>
                </template>
            </v-text-field>
        </v-row>
        <v-row>
            <v-col>
                <div  v-if="searchString && searchString.length>0">
                    <Str index="faq.search_bar.results"/>&nbsp;<a @click="clear"><Str index="faq.search_bar.clear_search"/></a>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="results.faq.length>0">
            <v-col>
                <v-expansion-panels v-model="expanded" flat>
                    <Question class="question" v-for="(pos, index) in results.faq[0].result" :key="`faq-index-${index}`" :index="index" :expanded="expanded" :record="records.faq[pos-1]" :language="language"/>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <v-expansion-panels v-model="expanded" flat>
                    <Question class="question" v-for="(record, index) in records.faq" :key="`faq-index-${index}`" :index="index" :expanded="expanded" :record="record" :language="language"></Question>
                </v-expansion-panels>            
            </v-col>
        </v-row>
    </template>
  </v-container>
</template>

<script>
import Question from '@/components/FAQ/Question.vue'
export default {
    name: "FAQ",
    props: {
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        Question
    },
    data(){
        return {
            results: {
                faq: []
            },
            searchString: null,
            timeout: null,
            expanded: null
        }
    },
    methods: {
        queueSearch(){
            clearTimeout(this.timeout)
            let self = this
            let searchString = self.searchString
            self.timeout = setTimeout(() => {
                self.search({searchString})
            }, 800);
        },
        search({searchString}){
            let query = searchString
            console.log({query})
            if(query && query.length>0){
                let controller = this.SearchController
                let faq = controller.documents.faq
                this.results = {
                    faq: faq ? faq.search([{field:"topics",query}, {field:"question",query}, {field:"answer",query}]) : null,
                }
            }else{
                this.results = {
                    faq: []
                }
            }
        },
        clear(){
            this.searchString = null
            this.search({})
        }
    },
    computed: {
        SearchController(){
            return this.$store.getters.SearchController
        },
        records(){
            return this.SearchController.records
        },
        labels(){
            return this.$store.getters.LabelController.labels.faq
        }
    }
}
</script>

<style lang="scss" scoped>
h1{
    color: $pink;
    font-size: 5em;
    font-weight: bold;
    line-height: 1.2em;
}
.mag{
    padding: 5px;
    border: 3px solid $green;
    background-color: $green;
    color: white;
    cursor: pointer;
}
.mag:hover{
    border: 3px solid white;
}
.question{
    border-bottom: 3px solid $lightgray;
}
</style>