<template>
<div>
  <v-list dense>
      <v-list-item v-for="(row, rowIndex) in question.rows" :key="`row-${rowIndex}`">
          <v-list-item-content>
              <v-container>
                  <v-row>
                      <v-col align-self="center" style="text-align: right;">
                          <strong>{{ row.text[language] }}</strong>
                      </v-col>
                      <v-col>
                          <v-data-table
                              dense
                              :headers="table.headers"
                              :items="getRecords(row.id)"
                              hide-default-footer
                              disable-pagination
                              />

                      </v-col>
                  </v-row>
              </v-container>                
          </v-list-item-content>
      </v-list-item>
  </v-list>
</div>
</template>

<script>
export default {
    name: "RadioTabelReport",
    props:{
        meta: {
            Type: Object,
            required: true
        },
        answers: {
            Type: Array,
            required: true
        },
        question:{
            Type: Object,
            required: true
        },
        language:{
            Type: String,
            required: false,
            default: 'en-ca'
        }
    },
    methods: {
        getRecords(rowId){
            return [this.calculations[rowId].answers]
        }
    },
    computed:{
        choices(){
            return this.question.ratingChoices
        },
        questionAnswers(){
            let data = {}
            let sectionId = this.meta.section.id
            let questionId = this.question.id

            let submissions = this.answers
            for(let i=0; i<submissions.length; i++){
                let record = submissions[i]
                if(!data[record.user_id]){
                    data[record.user_id] = null
                }
                let answer = record.answers[sectionId][questionId]
                data[record.user_id] = answer
            }

            return data
        },
        calculations(){
            let container = {}
            for(let r=0; r<this.question.rows.length; r++){
                let row = this.question.rows[r]
                container[row.id] = {
                    counter: 0,
                    answers: {}
                }


                for(let i=0 ;i<this.choices.length; i++){
                    let choice = this.choices[i]
                    container[row.id].answers[choice.value] = 0
                }                
            }

            for(let user_id in this.questionAnswers){
                let submission = this.questionAnswers[user_id]
                for(let rowId in submission.answers){
                    let answer = submission.answers && [rowId] ? submission.answers[rowId] : null
                    if(answer!=null){
                        container[rowId].counter++
                        container[rowId].answers[answer.value]++
                    }
                }
            }

            for(let rowId in container){
                let record = container[rowId]
                if(record.counter>0){
                    let answers = record.answers
                    for(let value in answers){
                        answers[value] = parseFloat((answers[value]/record.counter).toFixed(2),10)*100
                    }
                }
            }
            
            return container
        },
        table(){
            let headers = []
            for(let i=0; i<this.choices.length; i++){
                let item = this.choices[i]
                headers.push({
                    text: item.text[this.language],
                    value: item.value
                })
            }
            return {
                headers
            }
        }
    }
}
</script>

<style>

</style>