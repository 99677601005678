<template>
  <div>
    <Str index="authentication.account_verification.title"/>:&nbsp;{{states.verification}}
    <!-- <v-btn @click="verifyURL">verifyURL</v-btn> -->
    <!-- <v-btn @click="verified">verified</v-btn> -->
    
    <v-card width="600" v-if="states.verification!='idle'">
        <v-card-text>
            <Str index="authentication.account_verification.headline"/>:&nbsp;{{states.verification}}
            <hr>
            <Str index="authentication.account_verification.message"/>:&nbsp;{{message}}
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <router-link v-if="states.verification!='success'" :to="'/'">
                <v-btn><Str index="header.home"/></v-btn>
            </router-link>
            <router-link v-else :to="'/sign_in'">
                <v-btn><Str index="header.sign_in"/></v-btn>
            </router-link>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
    
    <v-dialog width="150" v-model="ui.busy" persistent>
        <v-card>
            <v-card-text>
                <Str index="authentication.account_verification.verifying"/>
            </v-card-text>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
    created: function(){
        this.verifyURL()
    },
    data: function(){
        return {
            ui: {
                busy: false
            },
            config: {
                pinLength: 6
            },
            username: null,
            user_id: null,
            verificationCode: null,
            states: {
                verification: 'idle'
            },
            message: null,
            verificationServer: process.env.NODE_ENV!='development' ? 'https://api.i-act.ca' : '/api'
        }
    },
    methods: {
        verifyURL: async function(){
            let verificationServer = this.verificationServer
            this.ui.busy = true
            this.states.verification = 'busy'
            let hash = this.hash
            let response
            try {
                response = await this.sendRequest('get',`${verificationServer}/verification/${hash}`,null,true)
                if(response.data){
                    this.username = response.data.email
                    this.user_id = response.data.user_id
                    this.verificationCode = response.data.code.toString()                    
                    await this.confirmSignUp()
                }else{
                    this.states.verification = 'invalid_link'
                }
            } catch (err) {
                console.error(err)
                this.states.verification = 'invalid_link'
            }
            this.ui.busy = false

        },
        async confirmSignUp(){
            let username = this.username
            let verificationCode = this.verificationCode
            try {
                let cognitoResult = await this.UserController.confirmSignUp({username, verificationCode});
                let apiResult = await this.verified()
                console.log('confirm signUp: success',{cognitoResult, apiResult})
                this.states.verification = 'success'
                let self = this
                setTimeout(() => {
                    let email = username
                    let user_id = self.user_id
                    this.sendRequest('put','/api/log',{user_id, action:'signIn', parameters: {email}})
                    self.$emit('success')
                }, 2000);
                
                setTimeout(() => {
                    let CampaignController = this.$store.getters.CampaignController
                    let campaign = CampaignController.storage().getCampaign()
                    if(campaign){
                        CampaignController.saveLog(campaign,'verify',{email: username, user_id: self.user_id})
                    }
                }, 3500)               
            } catch (err) {
                console.error('confirm signUp: error',err)
                this.states.verification = 'failed'
                this.message = err.message
            }
        },
        async verified(){
            let verificationServer = this.verificationServer
            try {
                let response = await this.sendRequest('put', `${verificationServer}/verification/${this.user_id}`,{email: this.username}, true)
                return response
            } catch (err) {
                console.error(err)
            }
        }
    },
    computed: {
        UserController(){
            return this.$store.getters.UserController
        },
        hash: function(){
            return this.$route.params.hash
        }
    }
}
</script>

<style>

</style>