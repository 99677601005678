<template>
    <v-container fluid dense>
        <v-row class="gridlines font-weight-bold">
            <v-col class="gridlines">
                <span v-html="question.text[language]"/>
            </v-col>
            <v-col align-self="start" class="desktop">
                <v-row>
                    <v-col align-self="start" style="text-align: center; font-size: 14px; display: block; height: 68px; border-left: 1px solid lightgray;" v-for="(choice, choiceIndex) in question.ratingChoices" :key="`grade_${questionSet.id}-${section.id}-${question.id}-${choiceIndex}`">
                        <span>{{choice.text[language]}}</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <template v-for="(row, rowIndex) in question.rows">
            <v-row class="gridlines" v-if="row" :key="`desktop-${questionSet.id}-${section.id}-${question.id}-${rowIndex}`">
                <v-col class="gridlines">
                    <span v-html="row.text[language]"/>
                </v-col>
                <v-col align-self="start" class="desktop">
                    <v-radio-group v-model="model.answers[row.id].value" :required="question.required" :rules="question.required ? rules.answered : []">
                            <v-row justify='center'>
                                <v-col class='d-flex justify-center' style="text-align: center; font-size: 8pt;" v-for="(choice, choiceIndex) in question.ratingChoices" :key="`${questionSet.id}-${section.id}-${question.id}-${rowIndex}-${choiceIndex}`">
                                    <!-- {{ choice.text[language] }} -->
                                    <v-radio :value="choice.value"/>
                                </v-col>
                            </v-row>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row class="gridlines"  v-if="row" :key="`mobile-${questionSet.id}-${section.id}-${question.id}-${rowIndex}`">
                <v-col class="gridlines mobile">
                    <v-select :label="$store.getters.LabelController.getLabel('quesrtion.rating_table.dropdown.label')" v-model="model.answers[row.id].value" :items="makeSelectChoices(question.ratingChoices)"/>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
export default {
    created(){
        this.init()
        this.question.$q = {
            init: this.init,
            reset: this.reset,
            randomize: this.randomize
        }
    },
    name: "ratingTable",
    props: {
        rules: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        section:{
            type: Object,
            required: true
        },
        questionSet:{
            type: Object,
            required: true
        },
        question:{
            type: Object,
            required: true
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        init(){
            let question = this.question
            let model = this.model
            let answers = {}
            for(let i=0; i<question.rows.length; i++){
                let row = question.rows[i]
                let value = null
                let other = null
                answers[row.id] = {
                    value,
                    other
                }
            }
            this.$set(model,'answers',answers)
        },
        reset(){
            this.init()
            this.$emit('resetInput',this._uid)
        },
        randomize(){
            let question = this.question
            let choices = question.ratingChoices
            
            function getRandomInt(max) {
                return Math.floor(Math.random() * max);
            }

            let answers = this.model.answers
            for(let id in answers){
                let answer = answers[id]
                let index = getRandomInt(choices.length)
                answer.value = choices[index].value
            }
        },
        makeSelectChoices(choices){
            let output = []
            
            for(let i=0; i<choices.length; i++){
                let choice = choices[i]
                output.push({
                    text: choice.text[this.language],
                    value: choice.value
                })
            }

            return output
        }
    },
    computed: {
        model(){
            return this.$attrs.value
        }
    }
}
</script>

<style lang="scss" scoped>
.row.gridlines{
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
}
.row.gridlines:first-child{
    border-top: 1px solid lightgray;
}
.col.gridlines{
    border-right: 1px solid lightgray;
}
.mobile{
    display: none;
}
@media screen and (max-width: 1300px) {
  .mobile{
    display: inline-block;
  }

  .desktop{
    display: none;
  }
}
</style>