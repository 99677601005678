<template>
<v-container fluid v-if="ready">
    <template v-if="completed">
        <v-row class="custom-gray">
            <v-col>
                <v-icon color="green" large>mdi-information</v-icon>
                <span v-if="pledge.id==1">Thank you for taking the Commitment to Act!</span>
                <span v-if="pledge.id==2">Thank you for reflecting on your Commitment to Act pledge!</span>
            </v-col>
        </v-row>
        <v-row class="custom-green">
            <v-col>
                <v-container dense fluid>
                    <v-row no-gutters>
                        <v-col style="text-align: left;">
                            <span v-if="pledge.id==1">Pledge submitted on: {{ new Date(PledgeController.submissions[1].date) }}</span>
                            <span v-if="pledge.id==2">Follow-up submitted on: {{ new Date(PledgeController.submissions[2].date) }}</span>                      
                        </v-col>
                        <v-col style="text-align: right;">
                            <v-btn text @click="print">Print <v-icon style="margin-left: 10px;" color="white">mdi-printer</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </template>
    <v-row>
        <v-col>
            <v-card class="pa-0" flat ref="form">
                <v-card v-for="step in steps" :key="`step-${step.id}`">
                    <div class="step" v-html="step.title[language]"/>
                    <v-card-subtitle v-if="step.subtitle[language]">
                        <span v-html="step.subtitle[language]"/>
                    </v-card-subtitle>
                    <v-card-text>
                        <!-- <json-viewer :value="step.questions"/> -->
                        <v-card flat v-for="question in step.questions" :id="getId(pledge, step, question)" :key="`pledge-${pledge.id}-step-${step.id}-question-${question.id}`">
                            <v-card-subtitle v-if="question.text[language]">
                                <div v-if="debug">{{ getId(pledge, step, question) }}</div>
                                <span v-html="question.text[language]"></span>                                
                            </v-card-subtitle>
                            <v-card-text>
                                <v-textarea v-if="!completed" outlined :ref="getId(pledge, step, question)" v-model="form[getId(pledge, step, question)].answer"  :required="question.required" :rules="question.required ? rules[question.type] : []" />
                                <div v-else v-text="form[getId(pledge, step, question)].answer"/>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
                <v-card-actions fluid class="pa-0"  v-if="$route.name!='Print'">
                    <v-container>
                        <v-row class="footer">
                            <v-col cols="9"></v-col>
                            <v-col style="text-align: center;">
                                <v-btn v-if="!completed" dark text @click="submit" :disabled="PledgeController.busy">
                                    Submit
                                </v-btn>
                                <v-btn v-else dark text @click="$router.push({name:'Dashboard'})">
                                    Continue
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
            
        </v-col>
    </v-row>
    <v-row v-if="debug">
        <v-col>
            <v-btn text @click="completeForm">
                completeForm
            </v-btn>
            <v-btn v-if="completed" text @click="deleteSubmission">
                Delete Submission
            </v-btn>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: 'TakePledge',
    props: {
        pledge: {
            type: Object,
            required: true
        },
        PledgeController: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created: function(){
        this.init()
        if(this.$route.name=='Print'){
            this.print()
        }
    },
    data: function(){
        return {
            ready: false,
            ui: {
                completed: false
            },
            rules: {
                textarea: [this.validateTextField]
            },
            formHasErrors: false,
            errorMessages: [],
            form: {},
            failed: [],
            timer: null
        }
    },
    methods: {
        print(){
            this.$router.push({path:`${this.$route.path}/print`})
            clearTimeout(this.timer)
            let self = this

            setTimeout(() => {
                window.print()
                setTimeout(() => {
                    self.$router.push({path:`${self.$route.matched[0].path}`})
                }, 800);
            }, 800);
        },
        completeForm(){
            let form = this.form
            for(let qid in form){
                let input = form[qid]
                input.answer = `Testing qid: ${qid}`
            }
        },
        validateTextField(v){
            if(!v || v.length==0){
                this.formHasErrors = true
                return "Required"
            }
            return true
        },
        async init(){
            let pledge = this.pledge
            let form = this.form
            for(let s=0; s<pledge.steps.length; s++){
                let step = pledge.steps[s]
                for(let q=0; q<step.questions.length; q++){
                    let question = step.questions[q]
                    let id = this.getId(pledge, step, question) //`${pledge.id}-${step.id}-${question.id}`
                    this.initQuestion({form, id, question})
                }
            }
            let self = this
            await self.PledgeController.getSubmissions()
            setTimeout(() => {
                self.ready = true
            }, 200);              
            // if(this.completed){
            //     this.form = submissions[pledge.id].form
            //     setTimeout(() => {
            //         self.ready = true
            //         setTimeout(() => {
            //             if(self.validate()){
            //                 self.completed()
            //             }
            //         }, 0);
            //     }, 200);
            // }else{
            //     setTimeout(() => {
            //         self.ready = true
            //     }, 200);  

            // }
        },
        initQuestion({form, id, question}){
            function template(question){
                switch(question.type){
                    case"textarea":
                        return {
                            answer: "",
                            other: ""
                        }
                    break;
                }
            }
            this.$set(form, id, template(question))
        },
        getId(pledge, step, question){
            return `${pledge.id}-${step.id}-${question.id}`
        },
        validate(){
            this.failed = []
            this.formHasErrors = false
            Object.keys(this.form).forEach(f => {
                if (!this.form[f].answer){
                    this.formHasErrors = true
                }
                let isValid = this.$refs[f][0].validate(true)
                if(!isValid){
                    this.failed.push(f)
                }
            })

            if(this.formHasErrors){
                let element = document.getElementById(this.failed[0])
                element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                return false
            }
            
            return true
        },
        async submit(){
            if(this.validate()){
                await this.PledgeController.submit(this.pledge, this.form)
                window.scrollTo(0, 0);
            }
        },
        async deleteSubmission(){
            await this.PledgeController.deleteSubmission(this.pledge.id)
        }
    },
    computed: {
        steps(){
            return this.pledge.steps
        },
        completed(){
            return this.PledgeController.submissions[this.pledge.id] ? true : false
        }
    },
    watch:{
        completed(){
            this.$emit('completion',{
                pledge: this.pledge,
                complete: this.complete
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-gray{
    background-color: $midgray;
    color: black;
}
.custom-green{
    background-color: $green;
    color: white;
}
.step{
    font-size: 1.5em;
    line-height: 1.2em;
    padding: 15px;
    color: white;
    font-weight: bold;
    background-color: $gray;
}

.footer{
    background-color: $pink;
}

.footer .col:nth-child(2){
    background-color: $gray;
}
</style>