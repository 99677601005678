<template>
  <v-expansion-panel>
    <v-expansion-panel-header expand-icon="mdi-window-close">
      <h3 v-html="`Q: ${record.question[language]}`"/>
      <template v-slot:actions>
        <v-icon style="padding: 3px;background-color: whitesmoke; border-radius: 50%;" :color="index==expanded ? 'red' : 'teal'">
            {{index==expanded ? 'mdi-minus' : 'mdi-plus'}}
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card>
        <v-card-text>
          <div class="answer" v-html="record.answer[language]"/>
        </v-card-text>
      </v-card>

    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
    props:{
        index: {
          type: Number,
          required: false
        },
        expanded: {
          type: Number,
          required: false
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        record: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.answer{
  border-left: 2px solid $pink;
  margin-left: 15px;
  padding-left: 15px;
}
</style>