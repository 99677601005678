<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
            <h3 align="center">Campaigns</h3>
            <v-btn depressed @click="editCampaign()">
                New Campaign
            </v-btn>
        </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-list dense>
                    <v-list-item  @click="editCampaign(campaignRecord)" :class="[{'selected':campaign.id==campaignRecord.id},{'inactive':campaignRecord.active=='N'}]" v-for="(campaignRecord) in campaigns" :key="`campaign-${campaignRecord.id}`">
                        <v-list-item-content>
                            <span v-if="debug">ID: {{ campaignRecord.id }} | CID: {{ campaignRecord.cid }}</span>
                            <v-list-item-title>Client: {{ campaignRecord.client }}</v-list-item-title>
                            <v-list-item-subtitle>URL: {{ getLink(campaignRecord.vanity) }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>                

            </v-col>


            <v-col v-if="ui.editCampaign">
              <v-tabs v-model="tabs">
                <v-tab>Campaign Config</v-tab>
                <v-tab :disabled="campaign.id==null">Campaign Logs</v-tab>
              </v-tabs>

              <br>
              <a target="_blank" v-if="validVanity && campaign.id" :href="getLink(campaign.vanity)">
                {{ getLink(campaign.vanity) }} --> {{ campaign.redirect }}
              </a>

              <v-tabs-items v-model="tabs">
                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <v-form v-model="valid" ref="campaignForm">
                          ID: {{ campaign.id }}
                          <v-switch v-model="campaign.active" true-value="Y" false-value="N" label="Active"/>
                          <v-list>
                              <v-list-item>
                                  <v-list-item-content>
                                      <v-text-field type="text" v-model="campaign.client" required :rules="rules.client" label="Client"/>
                                  </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                  <v-list-item-content>
                                      <v-text-field type="text" v-model="campaign.cid" required :rules="rules.cid" label="Campaign ID"/>
                                  </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                  <v-list-item-content>
                                      <v-text-field type="text" v-model="campaign.vanity" required :rules="rules.vanity" label="Vanity URL"/>
                                  </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                  <v-list-item-content>
                                      <v-text-field type="text" v-model="campaign.redirect" required :rules="rules.redirect" label="Redirect URL (/ for home)"/>
                                  </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                  <v-list-item-content>
                                      <v-text-field type="date" v-model="campaign.launch" required :rules="rules.launch" label="Launch Date"/>
                                  </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                  <v-list-item-content>
                                      <v-textarea v-model="campaign.notes" label="Notes"/>
                                  </v-list-item-content>
                              </v-list-item>
                          </v-list>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn x-large style="z-index: 100; position: fixed; right: 90px; bottom: 90px;" icon color="red" @click="close">
                        <v-icon x-large>mdi-cancel</v-icon>
                      </v-btn>
                      <v-spacer/>
                      <v-btn x-large style="z-index: 100; position: fixed; right: 25px; bottom: 90px;" icon color="green" :disabled="!valid" @click="save">
                        <v-icon x-large>mdi-content-save</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>  
                </v-tab-item>
                
                <v-tab-item>
                  <v-btn depressed small @click="getLogs(campaign)">Refresh</v-btn>
                  <br>

                  <template v-if="logs">
                    <v-card class="infoBox">
                      <v-card-title>
                        Clicks
                      </v-card-title>
                      <v-card-text>
                        {{ table.records.filter((record)=>{return record.event=='page_load'}).length }}
                      </v-card-text>
                    </v-card>  

                    <v-card class="infoBox">
                      <v-card-title>
                        Sign-Ups
                      </v-card-title>
                      <v-card-text>
                        {{ table.records.filter((record)=>{return record.event=='sign_up'}).length }}
                      </v-card-text>
                    </v-card>     

                    <v-card class="infoBox">
                      <v-card-title>
                        Verifications
                      </v-card-title>
                      <v-card-text>
                        {{ table.records.filter((record)=>{return record.event=='verify'}).length }}
                      </v-card-text>
                    </v-card>                  

                    <v-data-table
                      id="campaign_logs"
                      :headers="table.headers"
                      :items="table.records"
                      hide-default-footer
                      disable-pagination
                    />
                  </template>


                  <v-card v-else width="100" style="margin: 50px auto;">
                    <v-card-text style="text-align: center;">
                      <v-icon x-large>mdi-spin mdi-loading</v-icon>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>           
            </v-col>
        </v-row>
    </v-container>

    <v-data-table
      v-if="debug"
      :headers="routeTable.headers"
      :items="routeTable.records"
      hide-default-footer
      disable-pagination
    />

    <v-snackbar v-model="snackbar.display">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar.display = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>

export default {
    name: "CampaignAdmin",
    created(){
        this.init()
    },
    data(){
        return {
            tabs: 0,
            ui: {
                editCampaign: false
            },
            campaign: {},
            rules: {
              client: [
                (v)=>{if(!v || v.length==0){return "Required"} return true}
              ],
              launch: [
                (v)=>{if(!v || v.length==0){return "Required"} return true}
              ],
              cid: [
                (v)=>{if(!v || v.length==0){return "Required"} return true},
                ()=>{ if(!this.validCid){return "Must be unique"} return true}
              ],
              vanity: [
                (v)=>{if(!v || v.length==0){return "Required"} return true},
                ()=>{ if(!this.validVanity){return "Must be unique"} return true}
              ],
              redirect: [
                (v)=>{if(!v || v.length==0){return "Required"} return true},
                ()=>{ if(!this.validRedirect){return "Must be a valid path '/[pathname]'"} return true}
              ]
            },
            valid: false,
            snackbar: {
              display: false,
              message: null
            }
        }
    },
    methods: {
        formatDate(timestamp){
          let y = timestamp.getFullYear()
          let m = timestamp.getMonth()+1
          if(m<10){m = `0${m}`}
          let d = timestamp.getDate()
          if(d<10){d = `0${d}`}

          return `${y}-${m}-${d}`
        },
        async init(){
            let CampaignController = this.CampaignController
            await CampaignController.adminReady()
            this.campaign = CampaignController.campaignTemplate()
        },
        async getLogs(campaign){
          await this.CampaignController.getLogs(campaign)
        },
        editCampaign(campaign){
          let self = this
          let CampaignController = this.CampaignController
          
          
          let clone = JSON.stringify(campaign ? campaign : CampaignController.campaignTemplate())
          self.campaign = JSON.parse(clone)
          self.ui.editCampaign = true
          
          if(campaign==undefined){
            this.tabs=0
              setTimeout(() => {
                self.$refs.campaignForm.resetValidation()
              }, 200);
              }else{
                CampaignController.getLogs(this.campaign)
                this.tabs=1
              }              
        },
        close(){
            let CampaignController = this.CampaignController
            this.ui.editCampaign = false
            this.campaign = CampaignController.campaignTemplate()
        },
        validate(ref){
          this.$refs[ref].validate()
        },
        getLink(vanity){
          return `${location.protocol}//${location.host}/${vanity}`
        },
        async save(){
          try {
            let packet = this.campaign
            let response = await this.CampaignController.save(packet)
            if(response.status==200){
              this.snackbar.message = 'Saved'
              this.ui.editCampaign = false
              this.init()
            }
          } catch (error) {
            this.snackbar.message = 'Error saving'
            console.error('save',error)
          }
          this.snackbar.display = true
        }
    },
    computed:{
      CampaignController(){
        return this.$store.getters.CampaignController
      },
      routes(){
        return this.CampaignController.routes
      },
      campaigns(){
        return this.CampaignController.campaigns
      },
      logs(){
        return this.CampaignController.logs
      },
      validCid(){
          let campaign = this.campaign
          let cid = campaign.cid
          let campaigns = this.campaigns
          let matches = campaigns.filter((item)=>{return `${item.cid}`==`${cid}`})
          let tests = [
            cid!=null && cid.length>0,
            matches.length==0 || (matches[0].id==campaign.id)
          ]
          
          return !tests.includes(false)
      },
      validVanity(){
          let campaigns = this.campaigns
          let campaign = this.campaign
          let vanity = campaign.vanity
          let routes = this.routes
          let matches = campaigns.filter((item)=>{return `${item.vanity}`==`${vanity}`})
          let routeMatches = routes.filter((item)=>{return `${item.path}`==`/${vanity}`})
          let matchLength = (campaign.id===null ? 0 : 1)
          let tests = [
              vanity!=null && vanity.length>0,
              routeMatches.length <= matchLength,
              matches.length==0 || (matches[0].id==campaign.id)
          ]
          
          return !tests.includes(false)
      },
      validRedirect(){
          let campaign = this.campaign
          let redirect = campaign.redirect
          let routes = this.routes
          let routeMatches = routes.filter((item)=>{return `${item.path}`==`${redirect}`})
          let tests = [
              routeMatches.length==1 || redirect=='/'
          ]
          
          return !tests.includes(false)
      },
      debug(){
        return this.$store.getters.debug
      },
      table(){
        let output = {
          headers: [
            {
              text: 'event',
              align: 'start',
              sortable: false,
              value: 'event',
            },
            {
              text: 'cid',
              align: 'center',
              sortable: false,
              value: 'cid',
            },
            {
              text: 'vanity',
              align: 'center',
              sortable: false,
              value: 'vanity',
            },
            {
              text: 'user_id',
              align: 'center',
              sortable: false,
              value: 'user_id',
            },
            {
              text: 'timestamp_loc',
              align: 'center',
              sortable: false,
              value: 'timestamp_loc',
            },
          ],
          records: []
        }

        let logs = this.logs
        for(let i=0; i<logs.length; i++){
          let record = logs[i]
          let recordDate = new Date(record.timestamp)
          let offset = recordDate.getTimezoneOffset()
          record.timestamp_loc = Intl.DateTimeFormat('en-US', {dateStyle: 'medium', timeStyle: 'long'}).format(new Date(recordDate.getTime() + offset * 60000 ))
          output.records.push(record)
        }

        return output
      },
      routeTable(){
        let output = {
          headers: [
            {
              text: 'name',
              align: 'start',
              sortable: true,
              value: 'name',
            },
            {
              text: 'path',
              align: 'start',
              sortable: false,
              value: 'path',
            },
            {
              text: 'allowed',
              align: 'start',
              sortable: false,
              value: 'allowed',
            },
            {
              text: 'public',
              align: 'start',
              sortable: false,
              value: 'public',
            },
            {
              text: 'vanity',
              align: 'start',
              sortable: false,
              value: 'vanity',
            },
            {
              text: 'cid',
              align: 'start',
              sortable: false,
              value: 'cid',
            }
          ],
          records: []
        }

        let routes = this.routes
        for(let i=0; i<routes.length; i++){
          let route = routes[i]
          output.records.push({
            name: route.name,
            path: route.path,
            allowed: route.meta.allowed,
            public: route.meta.public,
            vanity: route.meta.campaign ? route.meta.campaign.vanity : null,
            cid: route.meta.campaign ? route.meta.campaign.cid : null
          })
        }

        return output
      }
    }
}
</script>

<style lang="scss" scoped>
.selected{
  color: white;
  background-color: slategray;
  font-weight: bold;
}
.inactive{
  background-color: gray;
}

.infoBox{
  display: inline-block;
  width: 150px;
  margin: 15px;
}

.infoBox .v-card__text{
  text-align: center;
  font-weight: bold;
}
</style>