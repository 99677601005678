<template>
<div style="position: relative;">
  <v-container class="pa-0" fluid dense>
    <v-row>
        <v-col cols="12" sm="6" id="title-desc-wrapper">
            <h1 class="component-title"><Str index="dashboard.recommended_videos.title"/></h1>
            <p style="font-size: 10pt;"><Str index="dashboard.recommended_videos.description"/></p>
        </v-col>
        <v-col/>
    </v-row>
    <v-row no-gutters class="pa-0">
        <template v-if="$vuetify.breakpoint.width>=768">
        <v-col class="pa-0 box" style="position: relative; left: 18px;">
          <Shape_1 v-if="recommendedVideos[0]" style="position: relative;" class="shape-1" :options="{triangle:{height: flexHeight, width: 73}, fontColor:'white', backgroundColor: 'recommended-pink'}">
            <template v-slot:content>
              <VideoCard_1 :debug="debug" @gotoVideo="controller.gotoVideo(recommendedVideos[0])" :video="recommendedVideos[0]" :language="language"/>
            </template>
          </Shape_1>
        </v-col>
        <v-col class="pa-0 box" style="position: relative; right: 18px;">
          <Shape_2 v-if="recommendedVideos[1]" style="position:relative; top:-60px;" class="shape-2" :options="{triangle:{height: flexHeight, width: 65}, fontColor:'black', backgroundColor: 'recommended-yellow'}">
            <template v-slot:content>
              <VideoCard_2 :debug="debug" @gotoVideo="controller.gotoVideo(recommendedVideos[1])" :video="recommendedVideos[1]" :language="language" @videoTitleHeight="updateVideo2TitleHeight"/>
            </template>
          </Shape_2>
        </v-col>
        </template>
        <template v-else><!-- Display recomended videos in carousel for viewport width smaller than 767px -->
            <v-carousel v-model="carousel_model" 
                        class="custom"
                :continuous="false"
                :cycle="true"
                :show-arrows-on-hover="false"
                :show-arrows="true"
                height="auto"
                hide-delimiter-background
                delimiter-icon="mdi-minus">
                <v-carousel-item style="height: auto;">
                    <v-container class="bgcolor-brand-pink">
                    <VideoCard_1 :debug="debug" @gotoVideo="controller.gotoVideo(recommendedVideos[0])" :video="recommendedVideos[0]" :language="language"/>
                    </v-container>
                </v-carousel-item>
                <v-carousel-item style="height: auto;">
                    <v-container class="bgcolor-brand-yellow">
                    <VideoCard_1 :debug="debug" @gotoVideo="controller.gotoVideo(recommendedVideos[1])" :video="recommendedVideos[1]" :language="language"/>
                    </v-container>
                </v-carousel-item>      
            </v-carousel>            
        </template>
    </v-row>
    <!-- <v-row no-gutters class="custom-gray" style="z-index: 5; position: relative; top: -80px; padding: 23px; height: 80px;"/> -->
  </v-container>  
  <v-btn text dark @click="$router.push({name:'Educational Videos'})" id="all-videos">
        <Str class="text-decoration-underline" index="dashboard.recommended_videos.all_videos"/><v-icon color="white">mdi-menu-right</v-icon>
  </v-btn>
</div>
</template>

<script>
import VideoCard_1 from './lib/recommendedCard_1.vue'
import VideoCard_2 from './lib/recommendedCard_2.vue'
import Shape_1 from '@/components/Shapes/1.vue'
import Shape_2 from '@/components/Shapes/2_1.vue'
export default {
    props: {
        recommendedVideos: {
          type: Array,
          required: true
        },
        controller:{
          type: Object,
          required: true
        },
        language: {
          type: String,
          required: false,
          default: 'en-ca'
        },
        debug:{
          type: Boolean,
          required: false,
          default: false
      }
    },
    components: {
      VideoCard_1,
      VideoCard_2,
      Shape_1,
      Shape_2    
    },
    data(){
        return {
            carousel_model: 0,
            video2_title_height: 32
        }
    },
    methods: {
        updateVideo2TitleHeight(param){
            this.video2_title_height = param;
        }
    },
    computed: {
        flexHeight(){
            let width  = this.$vuetify.breakpoint.width
            let height
            switch(true) {
                case width>=1904:
                  height = 350
                  break
                case width>=1864:
                  height = 330
                  break
                case width>=1764:
                  height = 311
                  break
                case width>=1664:
                  height = 291
                  break
                case width>=1564:
                  height = 285
                  break
                case width>=1464:
                  height = 275
                  break
                case width>=1364:
                  height = 261
                  break
                case width>=1264:
                  height = 265
                  break
                case width>=1200:
                case width>=1100:
                case width>=1000:
                  height = 250
                  break         
                case width>=960:
                  height = 250
                  break
                  
                case width >= 900:
                  height = 329
                  break
                  
                case width >= 876:
                  height = 308
                  break                  
                
                case width >= 850:
                  height = 301
                  break
                  
                case width >= 825:
                  height = 293
                  break
                case width >= 810:
                  height = 291
                  break                  
                  
                case width >= 798:
                    height = 288
                    break               
                case width >= 766:
                    height = 286
                    break                     
                case width >= 750:
                    height = 276
                    break
                case width >= 740:
                    height = 273
                    break                    
                case width >= 730:
                    height = 271
                    break
                case width >= 720:
                    height = 269
                    break                     
                case width >= 700:
                    height = 264
                    break
                case width >= 674:
                    height = 275
                    break                    
                case width >= 650:
                    height = 267
                    break
                    
                case width >= 630:
                    height = 261
                    break                    

                case width >= 626:
                    height = 260
                    break
                    
                case width >= 619:
                    height = 258
                    break
                    
                case width >= 615:
                    height = 257
                    break
                case width >= 611:
                    height = 256
                    break
                case width >= 600:
                    height = 255
                    break                   
                default:
                  height = 251
            }
            
            if (this.video2_title_height>32 && this.video2_title_height<=46){
                height = height + 6
            }
            else if (this.video2_title_height>46 && this.video2_title_height<=60){
                height = height + 12
            }
            else if (this.video2_title_height>60 && this.video2_title_height<=74){
                height = height + 18
            }
            
            return height
        }

      // recommendedVideos(){
      //   let output = []
      //   if(this.controller){
      //     output = this.controller.recommendedVideos
      //   }
      //   return output
      // }
    },
    watch: {
        video2_title_height(after, before){
            this.$forceUpdate();
        }
    }
}
</script>

<style lang="scss" scoped>
.box{
  width: 100%;
  //min-height: 360px;
  position: relative;
  background-color: $gray;
}

.component-title{
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.2em;
}

.v-btn#all-videos {
    position: absolute;
    bottom: 15px;
    right: 30px;
}

#title-desc-wrapper {
    padding: 30px 0px 30px 30px;
}

@media only screen and (max-width: 767px) {
    .v-btn#all-videos{
        position: relative;
        bottom: 0;
        left: 0;
    }
    
    #title-desc-wrapper {
        padding: 12px;
    }
}

::v-deep .custom .v-carousel__controls__item.v-btn {
  color: black;
  background-color: white;
}

:v-deep .custom .v-carousel__controls__item.v-btn.v-btn--active {
  color: green;
}

:v-deep .custom .v-carousel__controls__item.v-btn.v-btn--active:before {
  opacity: 0;
}

:v-deep .custom .v-carousel__controls__item.v-btn:hover {
  color: blue;
}

:v-deep .custom .v-carousel__controls__item.v-btn:hover:before {
  opacity: 0;
}

::v-deep .v-window {
    overflow: initial;   
}

::v-deep .v-carousel {
    overflow-y: initial;
}

::v-deep .v-window__prev {
    left: initial !important;
    right: 36px !important;
    top: -39px;
}
::v-deep .v-window__next {
    top: -39px;
}

</style>