<template>
<div class="dashboard-component">
<v-container fluid v-if="$route.name=='Dashboard'">
    <v-row v-if="$vuetify.breakpoint.smAndDown">
        <v-col>
          <UserInfo/>
        </v-col>
    </v-row>    
    <v-row :class="[{'flex-column':$vuetify.breakpoint.smAndDown}]">
      <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="1"/>
      <v-col cols="12" md="6" class="custom-gray">
        <RecommendedVideos v-if="$store.getters.VideoController.initialized" :language="language" :debug="debug" :controller="$store.getters.VideoController" :recommendedVideos="$store.getters.VideoController.recommendedVideos"/>
      </v-col>
      <v-col class="pa-0">
        <v-container>
          <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-col>
              <UserInfo/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card tile flat>
                <UserProgress v-if="$store.getters.VideoController.initialized" :language="language" :debug="debug" :VideoController="$store.getters.VideoController" :CreditController="$store.getters.CreditController"/>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="1""/>
    </v-row>
      
    <v-row>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="1"/>
        <v-col cols="12" md="6">
            <div>
                <UserCertificates v-if="$store.getters.CreditController.initialized" :language="language" :debug="debug"/>
            </div>
        </v-col>
        <v-col>
          <v-container>
            <v-row style="margin-bottom: 25px;" v-if="$store.getters.accreditation_expired!==true">
              <router-link text :to="{name:'Invite'}" class="invite-button">
                  <Str class="text-weight-bold" index="dashboard.cta.share_colleague"/>
                  <v-icon large>mdi-menu-right</v-icon>
              </router-link>
            </v-row>
            <v-row :style="{'border': $vuetify.breakpoint.width<=1024 ? '6px solid #C9126E' : 'none'}" v-if="$store.getters.accreditation_expired!==true">
              <v-col class="pa-0">
                <UserPledges v-if="$store.getters.QuestionSetController.initialized" :language="language" :debug="debug" :QuestionSetController="$store.getters.QuestionSetController" style="background-color: white;"/>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="1"/>
    </v-row>
                  
      <v-row v-if="$store.getters.QuestionSetController.initialized && $store.getters.VideoController.watched.length>0" style="margin-top: 25px;">
        <v-col cols="1"/>
        <v-col style="text-align: center;">
          <router-link style="text-decoration: none;" v-if="!$store.getters.QuestionSetController.submissions[$router.options.routes.filter((item)=>{return item.name=='Evaluation'})[0].meta.questionSet_id]" text :to="{name:'Evaluation'}">
            <div class="evaluation-button"><Str index="dashboard.cta.complete_evaluation"/><v-icon large color="white">mdi-menu-right</v-icon></div>
          </router-link>
          <div v-else-if="debug">
            <v-btn class="debug" @click="$store.getters.QuestionSetController.deleteSubmission($router.options.routes.filter((item)=>{return item.name=='Evaluation'})[0].meta.questionSet_id)">
              Delete Evaluation<v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>          
        </v-col>
        <v-col cols="1"/>
      </v-row>      
</v-container>
<router-view></router-view>
</div>
</template>

<script>
import RecommendedVideos from '@/components/Dashboard/RecommendedVideos'
import UserInfo from '@/components/Dashboard/UserInfo'
import UserProgress from '@/components/Dashboard/UserProgress'
import UserCertificates from '@/components/Dashboard/UserCertificates'
import UserPledges from '@/components/Dashboard/UserPledges'
export default {
  props: {
    language: {
      type: String,
      require: true
    }
  },
  components:{
    RecommendedVideos,
    UserInfo,
    UserProgress,
    UserCertificates,
    UserPledges
  },
  computed: {
    debug(){
      return this.$store.getters.debug
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-component{
  margin-top: 40px;
}
.invite-button{
  text-align: center;
  background-color: $yellow;
  width: 100%;
  padding: 25px;
}
a.invite-button{
  text-decoration: none;
  color: black;
  font-size: 1.2em;
  border: 3px solid #efd498;
}

.evaluation-button{
  text-decoration: none;
  text-align: center;
  background-color: $green;
  width: 100%;
  padding: 25px;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  border: 3px solid #baefbf;
}
.custom-gray{
  background-color: #E3E6DF;
}
@media only screen and (max-width: 767px) {
    .custom-gray{
        background-color: $gray;
        color: white;
    }
}

$carousel-controls-bg: #ff0000;
</style>