<template>
  <v-container>
    <v-row>
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" sm="6" md="5">
            <h1><Str index="contactUs.title"/></h1>
            <div v-if="$store.getters.accreditation_expired!==true"><Str index="contactUs.description"/></div>
            <div v-if="$store.getters.accreditation_expired===true"><Str index="contactUs.description_closure"/></div>
        </v-col>
        <v-col cols="12" sm="6" md="5">
            <v-container fluid>
                <v-row v-if="error" class="custom-error">
                    <v-col cols="1" align-self="center">
                        <v-icon color="orange" large>
                            mdi-alert
                        </v-icon>
                    </v-col>
                    <v-col align-self="center">
                        <Str index="contactUs.error_notice"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-card width="100%" outlined elevation="3">
                        <v-card-text>
                            <v-form v-model="valid" ref="form">
                                <v-container fluid dense>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="form.first" :label="$store.getters.LabelController.getLabel('contactUs.form.first_name')" required :rules="rules.required"/>
                                        </v-col>
                                        <v-col>
                                            <v-text-field v-model="form.last" :label="$store.getters.LabelController.getLabel('contactUs.form.last_name')" required :rules="rules.required"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="form.email" :label="$store.getters.LabelController.getLabel('contactUs.form.email')" required :rules="rules.email"/>
                                            <v-select v-model="form.subject" :items="presetSubjectList" required :rules="rules.required"
                                                :label="$store.getters.LabelController.getLabel('contactUs.form.subject')"                                                      
                                                persistent-placeholder :placeholder="$store.getters.LabelController.getLabel('contactUs.form.subject_placeholder')"                                                       
                                                />
                                            <v-textarea v-model="form.message" :label="$store.getters.LabelController.getLabel('contactUs.form.message')"  required :rules="rules.required"/>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn tile x-large dark color="black" @click="reset"><Str index="contactUs.form.reset"/></v-btn>
                            <v-btn tile x-large dark class="bg-blue-1" @click="submit"><span v-if="busy">...</span><Str v-else index="contactUs.form.submit"/></v-btn>
                        </v-card-actions>                         
                    </v-card>
                </v-row>
            </v-container>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1"></v-col>
    </v-row>
      
    <v-snackbar color="#F8B51E" multi-line vertical v-model="ui.snackbar.display" timeout="-1">
        <Str index="ui.snackbar.message"/>
        <template v-slot:action="{ attrs }">
            <v-btn dark class="pink-brand" tile v-bind="attrs" @click="ui.snackbar.display = false">
                <Str index="authentication.form.button.close"/>
            </v-btn>
        </template>
    </v-snackbar>      
  </v-container>
</template>

<script>
export default {
    data(){
        return {
            busy: false,
            error: false,
            valid: false,
            form: {
                first: null,
                last: null,
                email: null,
                message: null
            },
            rules: {
                email: [
                    this.notNull,
                    this.validEmail
                ],
                required: [this.notNull]
            },
            ui: {
                snackbar: {
                    display: false,
                    message: null
                }
            }
        }
    },
    computed: {
        presetSubjectList() {
            let contactUsSubjects = this.$store.getters.LabelController.labels.contactUs.subject
            let labelController = this.$store.getters.LabelController
            let otherIndex = 0
            let subjectLabelKeys = Object.keys(contactUsSubjects).map( (key,index) => {
                if (key==='other'){
                    otherIndex = index
                }
                return 'contactUs.subject.'+key
            })
            //Swap Other subject to tail of the list
            const tmpOtherKey = subjectLabelKeys[otherIndex]
            const lastIndex = subjectLabelKeys.length-1
            subjectLabelKeys[otherIndex] = subjectLabelKeys[lastIndex]
            subjectLabelKeys[lastIndex] = tmpOtherKey
            return subjectLabelKeys.map(key => labelController.getLabel(key))
        },
        language(){
            return this.$store.getters.LanguageController.language
        }
    },
    methods: {
        notNull(v){
            if(!v || v.length==0){
                return this.$store.getters.LabelController.getLabel('contactUs.form.required_field')
            }
            return true
        },
        validEmail(email){
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!(email && re.test(email))){
                return this.$store.getters.LabelController.getLabel('contactUs.form.invalid_email')
            }
            return true
        },
        validate () {
            return new Promise((resolve)=>{
                let self = this
                self.$refs.form.validate()
                setTimeout(() => {
                resolve(self.valid)
                }, 200);
            })
        },
        reset () {
            this.error = false
            this.valid = false
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form .resetValidation()
        },
        async submit(){
            let self = this
            if(!self.busy && await self.validate()){
                self.busy = true
                try {
                    let packet = JSON.stringify(self.form)
                    packet = JSON.parse(packet)
                    packet.language = this.language                   
                    packet.hubspot_id = this.$store.getters.UserController.user!==undefined ? this.$store.getters.UserController.user.attributes['hubspot_id'] : null
                    let response = await self.sendRequest('post','/api/contact_us',packet)
                    //console.log(response)
                    if (response.status===200){
                        this.ui.snackbar.message = "contactUs.message.success"
                        this.ui.snackbar.display = true
                        self.reset()
                    }
                    else {
                        this.ui.snackbar.message = "contactUs.message.failure"
                        this.ui.snackbar.display = true                        
                    }   
                } catch (error) {
                    //console.log(error)
                }
            }else{
                self.error = true
            }
            self.busy = false
        }
    },
    watch: {
        language(after, before){
            this.reset();
            this.$forceUpdate()
        }
    }
}
</script>

<style lang="scss" scoped>
h1{
    font-size: 4em;
    font-weight: 900;
    color: $pink;
}    
.custom-error{
    background-color: black;
    color: white;
}
::v-deep .bg-blue-1 {
    background-color: $blue-1 !important;
}

::v-deep .pink-brand {
    background-color: $pink !important;
}
</style>