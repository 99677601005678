<template>
  <v-card flat>
    <v-row v-if="signInError" class="custom-error mb-5 pa-3" no-gutters>
        <v-col cols="1" align-self="center">
            <v-icon color="orange" large>mdi-alert</v-icon>
        </v-col>
        <v-col align-self="center">
            <Str :index="signInErrorMsg"/>
        </v-col>
    </v-row>
    <v-form ref="form" v-model="validForm">
        <section v-if="ui.state=='login'">
            <v-text-field ref="email" v-model="form.email" @keyup="rememberMe=false" @keydown.enter="signIn" :label="$store.getters.LabelController.getLabel('authentication.form.label.email')" required :rules="rules.email" prepend-icon="mdi-shield-account-outline"/>
            <v-text-field v-model="form.password" label=" " required :rules="rules.password" :type="ui.showPassword ? 'text' : 'password' " :append-icon="ui.showPassword ? 'mdi-eye-off-outline' : 'mdi-eye'" @keydown.enter="signIn" @click:append="ui.showPassword=!ui.showPassword" prepend-icon="mdi-shield-key-outline" :hint="$store.getters.LabelController.getLabel('authentication.form.label.password')" persistent-hint/>
            <v-card-actions>
                <v-checkbox v-model="rememberMe" :disabled="!validEmail" :label="$store.getters.LabelController.getLabel('authentication.form.label.remember_me')"></v-checkbox>
                <v-spacer></v-spacer>
                <a @click="ui.state='forgotPassword'"><Str index="authentication.form.label.forgot_password"/></a>
            </v-card-actions>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="!validForm || busy" @click="signIn" :class="ui.buttonClass" dark x-large tile><Str index="authentication.form.button.sign_in"/></v-btn>
            </v-card-actions>
        </section>
        <section v-if="ui.state=='forgotPassword'">
            <ChangePassword v-model="form.email" @back="ui.state='login'" @success="signIn"/>
        </section>
    </v-form>
    <v-snackbar
        v-model="ui.snackbar.display"
        >
        {{ ui.snackbar.message }}

        <template v-slot:action="{ attrs }">
            <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="ui.snackbar.display = false"
                >
                Close
            </v-btn>
        </template>
    </v-snackbar>    
  </v-card>
</template>

<script>
import ChangePassword from './ChangePassword.vue'
export default {
    created(){
        this.form.email = this.model
        let rememberMe = this.cache.storage.local.get('rememberMe')
        if(rememberMe){
            this.rememberMe = true
            this.form.email = rememberMe
        }
        this.formRules = new this.formRulesAPI(this)
    },
    mounted(){
        let self = this
        // Following resolves ovarlaping browser saved credentials by input field label
        setTimeout(() => {
            window.localStorage.clear();            
            const els = document.querySelectorAll("input:-webkit-autofill")
            console.log(els)
            els.forEach((el) => {
                const label = el.parentElement.querySelector("label")
                label.classList.add("v-label--active")
            })
            if (els.length===2){
                self.validForm = true             
            }
            else {
                self.$refs.email.focus()
            }
        }, 200)
    },
    components: {
        ChangePassword
    },
    data(){
        return {
            busy: false,
            validForm: false,
            formRules: undefined,
            ui: {
                state: 'login',
                snackbar: {
                    display: false,
                    message: null
                },
                showPassword: false,
                buttonClass: "bg-blue-1"
            },
            form: {
                email: null,
                password: null
            },
            rememberMe: false,
            verifyPassword: null,
            rules: {
                otp: [
                    v => this.formRules.rules.answered(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.required_field'),
                    v => this.formRules.rules.length(v,6) || 'Invalid PIN'
                ],
                checkbox: [
                    v => this.formRules.rules.checked(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.invalid_entry')
                ],
                email: [
                    v => this.formRules.rules.validEmail(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.invalid_entry')
                ],
                password: [
                    v => this.formRules.rules.answered(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.required_field')
                ]
            },
            signInError: false,
            signInErrorMsg: null
        }
    },
    methods: {
        async signIn(param=null){
            this.busy = true
            if (param!==null && param.state!==undefined && param.state==='success_reset_pwd'){
                this.form.email = param.email
                this.form.password = param.password
            }
            if(this.validForm){
                let form = this.form
                let packet = {
                    email:form.email, 
                    password: form.password
                }
                let path = this.cache.storage.local.get('pathAfterLogin')                
                let target = path ? {path} : {name: 'Dashboard'}
                let success = await this.controller.signIn(packet, ()=>{this.$router.push(target)})
                if(!success){                    
                    this.signInErrorMsg = this.controller.hubEvent.data.code=="UserNotConfirmedException" ? this.$store.getters.LabelController.getLabel('authentication.message.signIn.account_not_validated') 
                            + " " + this.$store.getters.LabelController.getLabel('authentication.message.signIn.sign_up_again')
                        : this.$store.getters.LabelController.getLabel('authentication.sigin.error')
                    this.ui.snackbar.message = this.controller.hubEvent.data.code=="UserNotConfirmedException" ? this.$store.getters.LabelController.getLabel('authentication.message.signIn.account_not_validated')
                        : this.$store.getters.LabelController.getLabel('authentication.message.signIn.failed')
                    this.ui.snackbar.display = true
                    this.ui.buttonClass="error"
                    let self = this
                    setTimeout(() => {
                        self.ui.buttonClass="bg-blue-1"
                    }, 1000);
                    this.signInError = true
                }
            }
            this.busy = false
        }
    },
    computed:{
        model(){
            return this.$attrs.value
        },
        controller(){
            return this.$store.getters.UserController
        },
        email(){
            return this.form.email
        },
        validEmail(){
            let formRules = this.formRules
            return formRules ? formRules.rules.validEmail(this.form.email) : false
        }
    },
    watch: {
        model(){
            this.form.email = this.model
        },
        rememberMe(){
            let rememberMe = this.rememberMe
            if(rememberMe){
                this.cache.storage.local.set('rememberMe',this.form.email)
            }else{
                this.cache.storage.local.clear('rememberMe')
            }
        },
        email(){
            if(this.validEmail){
                this.$emit('input',this.form.email)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled{
  background-color: $lightgray !important;
  color: $gray !important;
}

.custom-error{
    background-color: black;
    color: white;
}
::v-deep .bg-blue-1 {
    background-color: $blue-1 !important;
}
::v-deep .error {
    background-color: red !important;
}
</style>