<template>
    <div>
        <table width="100%" class="table-dump" :id="export_id" border="0" v-show="debug">
        <thead>
            <tr>
                <td>Submission ID</td>
                <template v-for="(section) in rawAnswers(questionSet, submissions)">
                        <td :key="`section-id-${section.definition.id}`">
                            <!-- SECTION: {{ section.definition.id }} -->
                            {{section.definition.text[language]}}
                        </td>
                        <template v-for="(question) in section.definition.questions">
                            <template v-if="question.type=='RatingTable'">
                                <td class="question-body" :key="`section-${section.definition.id}-question-${question.id}`">
                                    <span v-html="stripHtmlTags(question.text[language])"/>
                                </td>

                                <!-- <td :key="`section-${section.definition.id}-question-id-${question.id}`">
                                    QID: {{ question.id }}
                                </td> -->
    
                                <template v-for="(row) in question.rows">
                                    <!-- <td :key="`section-id-${section.definition.id}-question-id-${question.id}-row-id-${row.id}`">
                                        ID: {{ section.definition.id }}-{{ question.id }}-{{ row.id }}
                                    </td> -->
                                    <td class="question-row" :key="`section-${section.definition.id}-question-${question.id}-row-${row.id}`">
                                        <!--{{ row.text[language] }}-->
                                        {{stripHtmlTags(row.text[language])}}
                                    </td>
                                    
                                    <td class="question-choice td-data td-choice" v-for="choice in question.ratingChoices" :key="`section-${section.definition.id}-question-${question.id}-row-${row.id}-choice-${choice.id}`">                                      
                                        <!-- {{ choice.text[language] }}-->
                                        {{stripHtmlTags(choice.text[language])}}                        
                                    </td>                            
                                </template>
            
            
                            </template>
            
                            <template v-else-if="question.type=='Radio'">
                                <td :key="`section-${section.definition.id}-question-${question.id}`">
                                    <!-- <span v-html="question.text[language]"/> -->
                                    <span v-html="stripHtmlTags(question.text[language])"/>
                                </td>
                                <td class="td-data td-choice" v-for="choice in question.choices" :key="`section-${section.definition.id}-question-${question.id}-choice-${choice.id}`">
                                    <!-- {{ choice.text[language] }} -->
                                    {{stripHtmlTags(choice.text[language])}}                                    
                                </td>
                            </template>
                
                            <template v-else-if="question.type=='Textarea'">
                                <td :key="`section-${section.definition.id}-question-${question.id}`">
                                    <!-- <span v-html="question.text[language]"/> -->
                                    <span v-html="stripHtmlTags(question.text[language])"/>                                    
                                </td>
                            </template>
                
                            <template v-else>
                                <td :key="`section-${section.definition.id}-question-${question.id}`" colspan="100%">
                                    <json-viewer :value="question"/>
                                </td>
                            </template>
                        </template>
                    
                    
                </template>
            </tr>
        </thead>
        <tbody>
            <tr v-for="submission in submissions" :key="`submissions-${submission.id}`">
                <td>{{ submission.id }}</td>
                <template v-for="(section) in rawAnswers(questionSet, submissions)">
                        <td class="question-section" :key="`submission-section-id-${section.definition.id}`"/>
                        <template v-for="(question) in section.definition.questions">
                            <template v-if="question.type=='RatingTable'">
                                <td class="question-body" :key="`submission-section-${section.definition.id}-question-${question.id}`"/>
    
                                <template v-for="(row) in question.rows">
                                    <!-- <td :key="`submission-section-id-${section.definition.id}-question-id-${question.id}-row-id-${row.id}`"/> -->
                                    <td class="question-row" :key="`submission-section-${section.definition.id}-question-${question.id}-row-${row.id}`"/>
                                    
                                    <td class="question-choice td-data td-choice" v-for="choice in question.ratingChoices" :key="`submission-section-${section.definition.id}-question-${question.id}-row-${row.id}-choice-${choice.id}`">
                                        <template v-if="choice.value==submission.answers[section.definition.id][question.id].answers[row.id].value">
                                            1
                                        </template>
                                        <!-- <template v-else>
                                            <json-viewer :value="choice"/>
                                            <json-viewer :value="submission.answers[section.definition.id][question.id].answers[row.id]"/>
                                        </template> -->
                                    </td>
                                </template>
            
            
                            </template>
            
                            <template v-else-if="question.type=='Radio'">
                                <td :key="`submission-section-${section.definition.id}-question-${question.id}`"/>
                                <td class="td-data td-choice" v-for="choice in question.choices" :key="`submission-section-${section.definition.id}-question-${question.id}-choice-${choice.id}`">
                                    {{ choice.value == submission.answers[section.definition.id][question.id].answers.value ? 1 : '' }}
                                    <!-- <json-viewer :value="{value: choice.value, submission: submission.answers[section.definition.id][question.id].answers.value}"/> -->
                                </td>
                            </template>
                
                            <template v-else-if="question.type=='Textarea'">
                                <td :key="`submission-section-${section.definition.id}-question-${question.id}`">
                                    <!-- <span v-html="question.text[language]"/> -->  
                                    {{submission.answers[section.definition.id][question.id].answers.value}}
                                </td>
                            </template>
                
                            <template v-else>
                                <td :key="`submission-section-${section.definition.id}-question-${question.id}`" colspan="100%">
                                    Unknown Type
                                    <json-viewer :value="question"/>
                                </td>
                            </template>
                        </template>
                    
                    
                </template>
            </tr>
        </tbody>
        </table>

        <v-btn color="green" class="white--text" small v-if="allowDownload" @click="ExportToExcel(`${export_id}`,'xls')">Download: {{ exportButton }}</v-btn>

    </div>
</template>

<script>
export default {
    name: "Table",
    props: {
        allowDownload: {
            type: Boolean,
            required: false,
            default: false
        },
        export_id: {
            type: String,
            required: true
        },
        questionSet: {
            type: Object,
            required: true
        },
        submissions: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        // debug: {
        //     type: Boolean,
        //     required: true
        // },
        ExportToExcel: {
            type: Function,
            required: true
        },
        exportButton:{
            type: String,
            required: true
        }
    },
    methods: {
        rawAnswers(questionSet, submissions){
            let language = this.language
            let output = {}

            for(let s=0; s<questionSet.sections.length; s++){
                let section = questionSet.sections[s]
                output[section.id] = {
                definition: section,
                submissions: []
                }

                for(let i=0; i<submissions.length; i++){
                let submission = submissions[i]
                output[section.id].submissions.push({
                    record_id: submission.id,
                    user_id: submission.user_id,
                    created: submission.created,
                    sectionAnswers: submission.answers[section.id]
                })
                }
            }

            return output
        },
        stripHtmlTags(text){
            let html = text
            let div = document.createElement("div")
            div.innerHTML = html
            return div.textContent || div.innerText || ""
        }
    },
    computed: {
        debug(){
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
.table-dump td, .table-dump th{
    border: 1px solid gray;
}
td.question-body, th.question-body{
    color: gray;
    font-weight: bold;
    text-decoration: underline;
    border: 1px solid purple;
}

td.question-row{
    color: black;
    font-weight: bold;
    border: 1px solid black;
}
</style>