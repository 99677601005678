<template>
<div v-if="$vuetify.breakpoint.width>=960" class="MostLikedVideoCard" 
     :style="{'background-image':`URL('${background}')`,'padding-left':index==0 ? 'none' : index==1 ? '50px' : '30px'}">
    <v-card color="transparent" flat>
        <template v-if="index==1">
            <v-card-text>
                <div class="d-flex justify-center" style="position: relative;">
                    <img :src="video.thumbnail[language]" width="70%"/>
                    <v-btn icon style="position: absolute; left: 50%; top: 50%; transform: translateX(-200%) translateY(-50%);;" @click="VideoController.gotoVideo(video)">
                        <img style="width: 35px;" :src="video.status.finished ? icons.finished : icons.play"/>
                        <!-- <v-icon large>mdi-play-circle</v-icon> -->
                    </v-btn>
                </div>
            </v-card-text>    
            <v-card-title class="text-left pt-0" style="font-size:1.03rem; line-height: 1.5;">
                <span :style="{'color':color}" v-html="video.title[language]"/>
            </v-card-title>
            <v-card-subtitle style="min-height:66px !important; padding-bottom: 0;">
                <div :style="{'color':color}" style="text-align: left; font-size:0.87rem;" v-html="video.description[language].length<100 ? video.description[language] : video.description[language].substr(0, 100)+'...'"/>                   
                <div><hr size="1" :color="color" style="width:80%;"><p :style="{'color':color}" class="text-left pb-0">{{video[`${language}_views`]}}&nbsp;<Str index="program.videos.views"/></p></div>
            </v-card-subtitle>        
        </template>
        <template v-else>
            <v-card-title style="font-size:1.03rem; line-height: 1.5;">
                <span :style="{'color':color}" v-html="video.title[language]"/>
            </v-card-title>
            <v-card-subtitle style="min-height:66px !important; padding-bottom: 7px;">
                <div :style="{'color':color}" style="text-align: left; font-size:0.87rem;" v-html="video.description[language].length<100 ? video.description[language] : video.description[language].substr(0, 100)+' ...'"/>    
                <div><hr size="1" :color="color" style="width:80%;"><p :style="{'color':color}" class="text-left mb-0">{{video[`${language}_views`]}}&nbsp;<Str index="program.videos.views"/></p></div>
            </v-card-subtitle>
            <v-card-text>
                <div style="position: relative;">
                    <v-img :src="video.thumbnail[language]" width="64%"></v-img>
                    <v-btn icon style="position: absolute; left: 50%; top: 50%; transform: translateX(-200%) translateY(-50%);" @click="VideoController.gotoVideo(video)">
                        <img style="width: 35px;" :src="video.status.finished ? icons.finished : icons.play"/>
                        <!-- <v-icon large>mdi-play-circle</v-icon> -->
                    </v-btn>
                </div>
            </v-card-text>
        </template>
    </v-card>
    <sup class="debug" v-if="debug">Likes: {{video.likes}}</sup>
</div>
<div v-else :class="['CarouselMostLikedVideoCard', backgroundColor]">
    <v-card color="transparent" flat>          
        <template>
            <v-card-title style="font-size:1.03rem; line-height: 1.5;">
                <span :style="{'color':color}" v-html="video.title[language]"/>
            </v-card-title>
            <v-card-subtitle style="min-height:66px !important; padding-bottom: 7px;">
                <div :style="{'color':color}" style="text-align: left; font-size:0.87rem;" v-html="video.description[language].length<100 ? video.description[language] : video.description[language].substr(0, 100)+' ...'"/>    
                <div><hr size="1" :color="color" style="width:80%;"><p :style="{'color':color}" class="text-left mb-0">{{video[`${language}_views`]}}&nbsp;<Str index="program.videos.views"/></p></div>
            </v-card-subtitle>
            <v-card-text>
                <div style="position: relative;">
                    <v-img :src="video.thumbnail[language]" width="64%"></v-img>
                    <v-btn icon style="position: absolute; left: 50%; top: 50%; transform: translateX(-200%) translateY(-50%);" @click="VideoController.gotoVideo(video)">
                        <img style="width: 35px;" :src="video.status.finished ? icons.finished : icons.play"/>
                        <!-- <v-icon large>mdi-play-circle</v-icon> -->
                    </v-btn>
                </div>
            </v-card-text>
        </template>
    </v-card>
    <sup class="debug" v-if="debug">Likes: {{video.likes}}</sup>
</div>    
</template>

<script>
import icon_play from '@/assets/icons/ic-play.svg'
import icon_watched from '@/assets/icons/ic-watched.svg'
import background_pink from '@/assets/shape_1_pink.svg'
import background_yellow from '@/assets/shape_2_yellow.svg'
import background_green from '@/assets/shape_1_green.svg'

export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        index: {
            type: Number,
            required: true
        },
        VideoController: {
            type: Object,
            required: true
        },
        video: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        color: {
            type: String,
            required: false,
            default: 'black'
        }
    },
    computed: {
        background(){
            return this.backgroundImages[this.index]
        },
        backgroundImages(){
            return [
                background_pink,
                background_yellow,
                background_green                
            ]
        },
        icons(){
            return {
                play: icon_play,
                finished: icon_watched
            }
        },
        backgroundColor(){
            let bgColorClasses = ['bgcolor-brand-pink', 'bgcolor-brand-yellow', 'bgcolor-brand-green']
            return bgColorClasses[this.index]
        }
    }
}
</script>

<style lang="scss" scoped>
.MostLikedVideoCard{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    background-size: cover;
}
</style>