<template>
    <div class="shape-container">
        <div class="video-details">
            <div class="video-title" v-html="video.title[language]"/>

            <div class="video-description">
                <v-tooltip bottom max-width='300'>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >{{video.description[language].length<77 ? video.description[language] : video.description[language].substr(0, 77)}}&nbsp;<v-icon class="color-brand-green" small>mdi-dots-horizontal-circle</v-icon></span>
                  </template>
                  <span>{{video.description[language]}}</span>
                </v-tooltip>
            </div>

        </div>

        <!-- <div class="video-image" :style="{'background-image':`URL(${video.thumbnail[language]})`}">-->  
        <div class="video-image">
            <img style="width: 100%;" :src="video.thumbnail[language]"/>
            <div style="position:absolute; top: 50%;left: 50%;transform: translate(-50%, -50%);">
                <v-btn icon @click="gotoVideo(video)">
                    <img style="width: 35px;" :src="video.status.finished ? icons.finished : icons.play"/>
                </v-btn>
            </div>
        </div>
        <sub class="debug" v-if="debug">Likes: {{video.likes}}</sub>
    </div>
  </template>
  
  <script>
  import icon_play from '@/assets/icons/ic-play.svg'
  import icon_watched from '@/assets/icons/ic-watched.svg'
  export default {
      props: {
        debug: {
            type: Boolean,
            required: true
        },
        video: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
      },
      methods: {
          gotoVideo(video){
            this.$emit('gotoVideo',video)
          }
      },
      computed: {
        icons(){
            return {
                play: icon_play,
                finished: icon_watched
            }
        }
      }
  }
  </script>
  
<style lang="scss" scoped>
.shape-container{
  padding: 5px;
  height: auto;
  color: white;
  font-weight: bold;
}
.video-image{
    //height: 237px;
    display: flex;
    justify-content: center;
    background-size: contain;
    background-position: left center;
    position: relative;
}

.video-details{
    padding: 0;
}
.video-title{
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    padding-bottom: 12px;
}
.video-description{
    height: 50px;
    overflow-y: hidden;
    font-weight: normal;
    font-size: 12px;
    line-height: 1;
    cursor: help;
}

@media #{map-get($display-breakpoints, 'md-and-down')}{
.video-description{
    line-height:1;
    font-size:12px;
}
}

@media #{map-get($display-breakpoints, 'sm-and-down')}{
.video-description{
    height: 55px;
}
}
</style>