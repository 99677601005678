<template>
    <div v-if="controller">
        <CertificateTemplate :certificate="certificate"/>
        <center v-if="ready">
            <v-btn @click="print">Print</v-btn>
        </center>
    </div>
</template>

<script>
import CertificateTemplate from '@/components/Certificate/template.vue'
export default {
    components: {
        CertificateTemplate,
    },
    data(){
        return {
            ready: true,
            timer: null
        }
    },
    methods: {
        print(){
            this.ready = false
            let self = this
            setTimeout(() => {
                window.print()
                setTimeout(() => {
                   self.ready = true 
                }, 800);
            }, 800);
        }
    },
    computed: {
        controller(){
            return this.$store.getters.CreditController
        },
        certificates(){
            return this.controller.certificates
        },
        certificate(){
            return this.certificates[this.$route.params.record-1]
        },
        // css(){
        //     return `
        //     <style type="text/css">
        //         @page{size: landscape;}
        //         .certificate{
        //             margin: 0 auto;
        //             width: 1024px;
        //             height: 600px;
        //             border: 1px solid grey;
        //             background-color: white;
        //             border-radius: 12px;
        //             padding: 25px;
        //         }
        //         ul{
        //             margin: 50px auto;
        //             list-style: none;
        //             max-width: 600px;
        //         }
        //         ul li{
        //             border-bottom: 1px solid gray;
        //         }
        //         .app{
        //             border: none !important;
        //         }
        //         body{
        //             border: none !important;
        //         }
        //     </style>
        //     `
        // }
    }
}
</script>

<style>

</style>