<template>
    <div class="shape" :style="{color:options.fontcolor, 'background-image':`URL('${background}')`, 'background-size':'cover'}">
      <v-container style="padding-left:30px; padding-top: 17px; padding-right: 17px;">
          <v-row>
              <v-col cols="1" class="pa-0" :class="`border-${options.backgroundColor}`">                
                  <div class="triangle" :class="`triangle-${options.backgroundColor}`" :style="[{'position':'relative'},{'left': isSafari ? '5px' : '5px'}]"/>
              </v-col>
              <v-col class="content">
                  <slot name="content"/>
              </v-col>
          </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
import background_yellow from '@/assets/shape_2_yellow.svg'
  export default {
      props: {
          options: {
              type: Object,
              required: true
          }
      },
      computed: {
        background(){
            return background_yellow
        },          
        isSafari(){
            let ua = window.navigator.userAgent.toLowerCase()
            if (ua.indexOf('safari') != -1) { 
                if (ua.indexOf('chrome') > -1) {
                    return false
                } else {
                    return true
                }
            }
            return false

        }
      }
  }
  </script>
  
<style lang="scss" scoped>
.shape{
    display: inline-block;
    position: relative;
    color: black;
    width: 100%;
    z-index: 1;
  //   min-height: 400px;
}

.content{
    text-align: left;
    z-index: 1;
}

.custom-black{
    background-color: black;
}

.custom-darkgray{
    background-color: $darkgray;
}

.custom-gray{
    background-color: $gray;
}

.custom-midgray{
    background-color: $midgray;
}

.custom-lightgray{
    background-color: $lightgray;
}

.custom-pink{
    background-color: $pink;
}

.custom-green{
    background-color: $green;
}

.custom-blue{
    background-color: $blue;
}

.custom-yellow{
    background-color: $yellow;
}

.custom-recommended-yellow{
  background-color: $yellow;
  padding: 19px 20px 0px 12px !important;
}

.border-pink{
    border-right: 8px solid $pink;
}

.border-green{
    border-right: 8px solid $green;
}

.border-blue{
    border-right: 8px solid $blue;
}

.border-yellow{
    border-right: 17px solid $yellow;
}

.triangle{
  width: 0;
  height: 0;
  border-style: solid;
  // border-width: 0 0 400px 94px;
  // border-color: transparent transparent #007bff transparent;
}
.triangle-black{
    border-color: transparent transparent black transparent;
}
.triangle-darkgray{
    border-color: transparent transparent $darkgray transparent;
}
.triangle-gray{
    border-color: transparent transparent $gray transparent;
}
.triangle-midgray{
    border-color: transparent transparent $midgray transparent;
}
.triangle-lightgray{
    border-color: transparent transparent $lightgray transparent;
}
.triangle-pink{
    border-color: transparent transparent $pink transparent;
}
.triangle-green{
    border-color: transparent transparent $green transparent;
}
.triangle-blue{
    border-color: transparent transparent $blue transparent;
}
.triangle-yellow, .triangle-recommended-yellow{
    border-color: transparent transparent transparent transparent;
}
</style>